import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { base_url } from "../../../../Services/ApiUrl";
import { Box } from "@material-ui/core";
import axios from "axios";

export default function BibleSelect(props) {
  const { width, value, onChange } = props;
  const [bibleVersion, setBibleVersion] = useState(null);
  const [defaultVer, setDefaultVer] = useState(null);

  useEffect(() => {
    axios.get(`${base_url}sources?content_type=bible&access_tag=publishable&latest_revision=False&skip=0&limit=100`).then(function (
      response
    ) {
      const bible = response.data.map((item) => {
        const label =
          item.metaData.Abbreviation !== undefined
            ? item.metaData.Abbreviation
            : item.version.versionAbbreviation;
        return { value: item.sourceName, label: label };
      });
      let defValue = bible[0];
      //if want default bible as any specfic bible uncomment if
    //   if (
    //     bible.findIndex((b1) => b1.label === "UNFOLDINGWORDLITERALTEXT") !== -1
    //   ) {
    //     defValue = {
    //       value: "en_UNFOLDINGWORDLITERALTEXT_1_bible",
    //       label: "UNFOLDINGWORDLITERALTEXT",
    //     };
    //   }

      onChange(defValue);
      setDefaultVer(defValue);

      setBibleVersion(bible);
    });
  }, []);
  return (
    <Box style={{ width: width || 250 }}>
      {bibleVersion !== {} && bibleVersion !== null && (
        <Select
          maxMenuHeight={190}
          onChange={(option) => onChange(option)}
          options={bibleVersion}
          value={value ? value : defaultVer}
          placeholder={bibleVersion ? props.componentName : "Loading"}
          isSearchable={false}
          isClearable={false}
          isLoading={!bibleVersion}
          isDisabled={!bibleVersion}
        />
      )}
    </Box>
  );
}

BibleSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  width: PropTypes.number,
  componentName: PropTypes.string,
};
