import {Typography} from '@mui/material'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import React from 'react'
import styles from "../../Stylesheet/releasenotes.module.css"

export default function ReleaseNotes({showModal, setShowModal}) {
    return (
        <> {
            showModal ? <div className={
                styles.container
            }>
                <div className={
                    styles.modal
                }>
                    <Typography variant="h6" align="center"
                        style={
                            {display: "inline-block"}
                    }>
                        Release Notes
                    </Typography>
                    <CancelRoundedIcon style={
                            {
                                float: 'right',
                                cursor: "pointer"
                            }
                        }
                        onClick={
                            () => {
                                setShowModal(false)
                            }
                        }/>
                    <div className={
                        styles.releaseNoteContainer
                    }>
                        <h3>Release 1.0.0-beta1.0</h3>
                        <dl>
                            <dt>Dicitonary Management</dt>
                            <dd>
                                <li>perform -Add,Update and Delete operation in Dicitonary Management</li>
                            </dd>
                            <dt>Following Resources available for reference</dt>
                            <dd>
                                    <li>Other Bible</li>
                                    <li>Commentary</li>
                                    <li>Translation Notes</li>
                                    <li>Translation Word</li>
                            </dd>
                            <dt>Project Mangement</dt>
                            <dd>
                                    <li>Create Project</li>
                                    <li>add Users to the project</li>
                                    <li>Translation Notes</li>
                                    <li>add books to the Project</li>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- from the available list of sources<br></br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- upload  books(usfm/csv files)
                                    <li>View Progress</li>
                            </dd>
                            <dt>Use BCV(Book,chapter and Verse)dropdowns/Bible Reference Navigation to navigate through the project.</dt>
                            <dt>Alignment Editor</dt>
                            <dd>
                                <li>there are two panel (top panel show source word, bottom panel show target word)</li>
                                <li>We can see already translated word by just hovering on word (translated word will also highlight)</li>
                                <li>there are options to</li>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- add any word (either from dictionary or add new word)<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- alternative suggestions we have for selected word<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- link two words<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- unlink words which are already linked<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- reset selected word<br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- final submission button saves your work and push it to db<br></br>
                                <li>We can split word by doing double clicking on word</li>
                                <li>We can also combine multiple token or multiple suggestions by selecting multiple words</li>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div> : null
        } </>
    )
}
