
import React,{useEffect,useState} from 'react';
import useAxios from "./useAxios";
import Button from '@material-ui/core/Button';

//import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function SeeAlso(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  
  const { relatedSigns , onChange } = props;

  //const [defaultValue, setDefaultValue] = useState([]);

  //console.log(props.relatedSigns.length);
  
  
   const  defaultValue=relatedSigns;

     //console.log(props.relatedSigns)
   // const editWord=props.selectedWord; 
  //const defaultValue = props.relatedWord;
    //const defaultValue =[{word:"Apostle"},{word:"Choose"},{word:"Can"},{word:"Forever"}];
    
    const { response, loading, error } = useAxios({
      method: "get",
    //  url: "sources?content_type=commentary&access_tag=content&active=true&latest_revision=false&skip=0&limit=1000",
    url: "dictionaries/ins_IRV_1_dictionary?exact_match=false&word_list_only=true&active=true&skip=0&limit=100000",
    
    
  });

  useEffect(() => {
    if (response !== null) {

    
      setData(response);
      //console.log(data);
    }
    
 
  }, [response]);



  return (
    <div className={classes.root}>
     
      {defaultValue.length>0 ?
      <Autocomplete
        multiple
        id="tags-standard"
        options={data}
        defaultValue={defaultValue}
        onChange={(e,value)=>onChange(value)}
        // onChange={(event, value) => console.log(value.map(function (obj) {
        //     return obj.word;
        //   }))}
        getOptionLabel={(option) => option.word}

         getOptionSelected={(option,defaultValue)=> option.word===defaultValue.word}    
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Related Signs"
           // placeholder="Favorites"
          />
          
        )}
        
      />: <Autocomplete
      multiple
      id="tags-standard"
      options={data}
      onChange={(e,value)=>onChange(value)}
      getOptionLabel={(option) => option.word}
       getOptionSelected={(option,defaultValue)=> option.word===defaultValue.word}    
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Related Signs"
        />
        
      )}
      
    />
       } </div>
      );}
    