import { useState } from "react";
// import UploadProjectBook from './UploadProjectBook';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { API } from '../../store/api'
import ProjectSelect from './ProjectSelect';
import {useHistory} from 'react-router-dom'

import SnackBar from "./SnackBar";
import axios from "axios";
import Papa from "papaparse";
import { BIBLES_ABBRV_INDEX} from "../Tokenization/BCVDropdownComponents/BooksOfTheBible"
import { base_url } from "../../Services/ApiUrl";
import BibleSource from "./AddBooksViaBible/BibleSource";


//const baseURL = process.env.REACT_APP_BASE_URL || base_url;
const baseURL =  base_url;
const API = axios.create({ baseURL: baseURL, timeout: 45000 });

const UploadUsfmCsvFile = (Props) => {
  const projectId=Props.projectId;
  const projectName=Props.projectName;
  const [loading, setLoading] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  const [responseStatus, setResponseStatus] = useState([]);
  const [bibleVersion, setBibleVersion] = useState("");
  const [bibleBooks, setBibleBooks] = useState([]);

  const history=useHistory();

  const handleClose = () => {
    setResponseStatus([false]);
  };

  const clearState = () => {
    setSelectedFiles("");
    //setCsvReader([]);
  };

  const readFileAsText = (file) => {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();

      fr.onload = function () {
        resolve(fr.result);
      };

      fr.onerror = function () {
        reject(fr);
      };

      fr.readAsText(file);
    });
  };

const addBooks =()=>{
  if(bibleVersion===null||bibleVersion===''){
    setResponseStatus([true, "error", "Please upload a file or select source bible book "]);
  }else{
  const data = {
    projectId: projectId,
    selectedBooks: {
      bible: bibleVersion,
      books: bibleBooks
    },
  };
  

  
 if(data) 
{ 
 
API.put('autographa/projects', data,{
    headers: {
        app: "Autographa",
        "content-type": "application/json",
        "Authorization": `Bearer ${
            localStorage.getItem('token')
        }`
    }
})
    .then(function (response) {
      setResponseStatus([true, 'success', response.data.message]);
      setLoading(false);
      history.push("/home");
      window.location.reload();


    })
    .catch((error) => {
      setResponseStatus([true, 'error', error.response.data.details]);
      setLoading(false);
    });
}}
}

  const loadText = () => {
 
    if (selectedFiles.length > 0 ) {
      setLoading(true);

      let files = selectedFiles;
      let readers = []; //for usfm
      let csvreaders = []; //for csv
      for (let i = 0; i < files.length; i++) {
        //condition for USFM /sfm file
        if (
          (files[i].name.substring(
            files[i].name.lastIndexOf(".") + 1,
            files[i].name.length
          ) || files[i].name) === "usfm" ||
          (files[i].name.substring(
            files[i].name.lastIndexOf(".") + 1,
            files[i].name.length
          ) || files[i].name) === "sfm"
        ) {
          readers.push(readFileAsText(files[i]));
        } else {
            
          Papa.parse(files[i], {
            header: true,
            skipEmptyLines: true,

            complete:  function (results) {
              
              var njson = results.data.map((item) => {
                const container = {};
                container.sentenceId =
                  BIBLES_ABBRV_INDEX[item.Book.toLowerCase()] +
                  item.Chapter.padStart(3, "0") +
                  item.Verse.padStart(3, "0");
                container.surrogateId =
                  item.Book.toUpperCase() +
                  " " +
                  item.Chapter +
                  ":" +
                  item.Verse;
                container.sentence = item.Text;
                return container;
              });
              //merging two array csvreaders and njson
              
              Array.prototype.push.apply(csvreaders, njson);
            },
          });
        }

      } //for loop ends
      
      const start=()=>{
      Promise.all(readers).then((values) => {
        const data = {
          projectId: projectId,
           uploadedUSFMs: values,
           sentenceList: csvreaders,
        };
      
       if(( data.sentenceList )||data.uploadedUSFMs) 
      { 
      API.put('autographa/projects', data,{
          headers: {
              app: "Autographa",
              "content-type": "application/json",
              "Authorization": `Bearer ${
                  localStorage.getItem('token')
              }`
          }
      })
          .then(function (response) {
            setResponseStatus([true, 'success', response.data.message]);
            setLoading(false);
            history.push("/home");
            window.location.reload();


          })
          .catch((error) => {
            setResponseStatus([true, 'error', error.response.data.details]);
            setLoading(false);
          });
         }
      });
    }
    setTimeout(start,3500);
    } else {
      setResponseStatus([true, "error", "Please upload a file or select source bible book "]);
    }

    clearState();
  };

  return (
    <>
<div style={{textAlign:'center'}}>
      <h1>Add Books</h1>
      </div>
      <div style={{ width: "700px", padding: "20px", margin: "auto" }}>
        <Grid container spacing={5} justifyContent="center">
          <SnackBar responseStatus={responseStatus} handleClose={handleClose} />
          <Grid item xs={12} sm={11}>
            <ProjectSelect value={projectName} />
          </Grid>
          <Grid item xs={12} sm={11} style={{textAlign:'right'}}>
            <BibleSource projectBooks={Props.projectBooks} sourceLangCode={Props.projectSourceLangCode} bibleVersion={setBibleVersion} bibleBooks={setBibleBooks} selectedFiles={setSelectedFiles}/>
          </Grid>
          <Grid item xs={2}>

            <Button
              color="primary"
              size="small"
              variant="contained"
              component="span"
              onClick={bibleVersion==='Upload' ? loadText : addBooks}
            >
              Submit
            </Button>
            {loading && <CircularProgress size='1.5rem' />}
            
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default UploadUsfmCsvFile;
