import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Link,useHistory} from 'react-router-dom'
import styles from "../../Stylesheet/navbar.module.css"
import axios from 'axios';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { base_url } from '../../Services/ApiUrl';
import { AuthContext } from '../../Context/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
},
title: {
    flexGrow: 1
},
}));

const Navbar = () => {
const {login,setLogin} = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const logout = () =>{
    const token = localStorage.getItem('token')
    axios.get(`${base_url}user/logout`,{headers:{
      'content-type': 'application/json',
      app: "Autographa",
      Authorization: `Bearer ${token}`
    }}).then(()=>{
     // alert("Logout Successful")
     history.push("/");
      localStorage.removeItem('token')
      localStorage.removeItem('login')
      localStorage.removeItem('userId')
      setLogin(false);
      setAnchorEl(false);
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{ backgroundColor: "#353b48" }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} align="left">
        <Link to="/" className={styles["redirectLink"]}>
        SANKET MAST
          </Link>
          </Typography>
          {
           login?
            <>
                <Link to="/home" className={styles["redirectLink"]}>
                <Button color="inherit"> Home </Button>
              </Link>
    
              {/* Adding logout as a dropdown to the avatar */}

              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircleIcon fontSize="large" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  getContentAnchorEl={null}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </>

            :
            <>
            <Link to="/signup" className={styles["redirectLink"]}><Button color="inherit">Sign Up</Button></Link>
            <Link to="/login" className={styles["redirectLink"]}><Button color="inherit">Login</Button></Link>
            </>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;