import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { base_url } from '../../Services/ApiUrl';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function RemoveDialog(props) {
  const [open, setOpen] = useState(false);
  const [deleteWord, setDeleteWord] = useState(props.selectedWord);
  const [synonyms, setSynonyms] = useState(props.synonyms);
  const [description, setDescription] =useState(props.description);
  const [videourl, setVideoUrl] = useState(props.videourl);
  const [relatedSigns, setRelatedSigns] = useState(props.relatedSigns);
  const [remarks, setRemarks] = useState(props.remarks);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    //setDeleteWord('');
    setOpen(false);
  };
  const handleDelteWord=()=>{
console.log(deleteWord);
const token = localStorage.getItem("token");


  fetch(`${base_url}dictionaries/ins_IRV_1_dictionary`, {
    method: "PUT",
    body: JSON.stringify([{
      "word": deleteWord,
      "details": {
        "videoUrl": videourl,
        "synonyms": synonyms,
        "description": description,
        "relatedSigns": relatedSigns,
        "remarks": remarks
      },
      "active": false
    }]),
    headers: {
      "Content-type": "application/json",
       "Authorization": `Bearer ${token}`,
        "app": "Autographa"
    },
  })
    .then((response) => response.json())
    .then((json) => {
      //console.log(json);
      setSnackBarOpen(true);
      setOpen(false);
      window.location.reload();
    }) 
    .catch((error) => {
      console.error('Error:', error);
    });
  }



  return (
    <div>
        <Tooltip title="Remove Sign" aria-label="remove" placement="bottom">

      <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={handleClickOpen}
          >
              <DeleteOutlineOutlinedIcon style={{ color: '#ef5777' }} />
          {/* {console.log(index)} */}
          </IconButton>
</Tooltip>
           <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to <b>deactivate</b> the sign <b style={{color:'#e55039'}}>"{deleteWord}"</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDelteWord} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackBarOpen} anchorOrigin={ {vertical: 'top',
          horizontal: 'center',}} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="error">
          Deactivated Successfully!
        </Alert>
      </Snackbar>
    </div>
  );
}