import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { base_url } from "../../Services/ApiUrl";
import { AuthContext } from "../../Context/AuthContext";
import { useHistory } from "react-router-dom";

const useAxios = ({ url, method }) => {
  const [response, setResponse] = useState(null);
  const {setLogin}=useContext(AuthContext);
  const history =useHistory();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const API = axios.create({
    baseURL: base_url,
    headers: {
      app: "Autographa",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const fetchData = () => {
    API[method](url)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
        if(err.response.status===401)
        {
          history.push("/");
          localStorage.removeItem('token')
          localStorage.removeItem('login')
          localStorage.removeItem('userId')
          setLogin(false);
          alert("Session has Expired. Please Log in again")
    
        }else{
          alert("Error Caused, Please try again");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { response, error, loading };
};

export default useAxios;
