import React, { useState ,useEffect} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import SeeAlso from "./SeeAlso";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import useAxios from "./useAxios";
import { base_url } from "../../Services/ApiUrl";



export default function AddSign() {
  const [open, setOpen] = React.useState(false);
  const [availableSigns, setAvailableSigns] = useState([]);
  const [sign, setSign] = useState();
  const [signError, setSignError] = useState(null);
  const [signErrorLength, setSignErrorLength] = useState(false);
  const [urlError, setUrlError] = useState(null);
  const [url, setUrl] = useState("");
  const validUrl = require("valid-url");
  const [synonyms, setSynonyms] = useState('');
  const [description, setDescription] = useState('');
  const [relatedSigns, setRelatedSigns] = useState([]);

  const [remarks, setRemarks] = React.useState('');
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

 
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };
 



  
  const checkAvailablity=(e)=>{
    //e.preventDefault();
    
    //console.log(e);
    if(e.length>0){
      setSignErrorLength(false);

      setSign(e)
    const isFound = availableSigns.some(sign1 => {
    //console.log(sign);
      if (sign1.word.toLowerCase()  === e.toLowerCase() ) {
        return true;
      }
    
      return false;
    });
    
    if (isFound) {
      setSignError(true);
    }else{
      setSignError(false);
    }
  }
if(e===''){
  setSignError(null);
  setSign('');
}


  }

  const { response, loading, error } = useAxios({
    method: "get",
  //  url: "sources?content_type=commentary&access_tag=content&active=true&latest_revision=false&skip=0&limit=1000",
  url: "dictionaries/ins_IRV_1_dictionary?exact_match=false&word_list_only=true&active=true&skip=0&limit=100000",
  
  
});

useEffect(() => {
  if (response !== null) {

  
    setAvailableSigns(response);
    //console.log(data);
  }
  

}, [response]);



  const handleSubmit = (e) => {
    e.preventDefault();
//    console.log(relatedSigns);
//console.log(sign,signError);
    
    
    if(sign.length===0){


      setSignErrorLength(true);
    }else{
      setSignErrorLength(false);

    }
if(sign.length>0 && signError===false && urlError!==true)
{
  console.log(sign,synonyms,description,remarks,url,relatedSigns);
  const token = localStorage.getItem("token");

  fetch(`${base_url}dictionaries/ins_IRV_1_dictionary`, {
    method: "POST",
    body: JSON.stringify([{
      "word": sign,
      "details": {
        "videoUrl": url,
        "synonyms": synonyms,
        "description": description,
        "relatedSigns": relatedSigns,
        "remarks": remarks
      },
      "active": true
    }]),
    headers: {
      "Content-type": "application/json",
       "Authorization": `Bearer ${token}`,
        "app": "Autographa"
    },
  })
    .then((response) => response.json())
    .then((json) => {
      //console.log(json);
      setSnackBarOpen(true);
      setOpen(false);
      window.location.reload();
    }) 
    .catch((error) => {
      console.error('Error:', error);
    });


}

   };

  const handleClickOpen = () => {
    setOpen(true);
    setSign('');
  setSignError(null);
  setUrlError(null);
  setSignErrorLength(false);
  setSynonyms('');
    setDescription('');
    setUrl('');
    setRelatedSigns('');
    setRemarks('');


  };

  const handleClose = () => {
    setOpen(false);
  };
  // const classes = useStyles();
  return (
    <div>
      <Tooltip title="Add New Sign" aria-label="add" placement="right">
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {/* <b >+ </b> Sign */}

          <AddOutlinedIcon style={{ color: "#3c40c6" }} />
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ color: "#ff4757" }}>
          Add New Sign
        </DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <div style={{ display: "flex",justifyContent:'space-between' }}>
            <TextField
              autoFocus
              margin="dense"
              id="sign"
              label="Enter the Sign"
              value={sign}
              type="text"
              error={signErrorLength}
              required
              onChange={(e)=>{checkAvailablity(e.target.value)}}
              //fullWidth
              style={{marginRight:40}}
            />
        <TextField
              // autoFocus
              margin="dense"
              id="synonyms"
              label="Synonyms"
              type="text"
              onChange={(e)=>{setSynonyms(e.target.value)}}
              //fullWidth
              //style={{marginLeft:'70px'}}
            />
            {/* <Button color="primary" variant="outlined" style={{height:30,margin:'auto'}} onClick={checkAvailablity}>Check Availablity</Button> */}
            </div>
            {signError===true ?(<><p style={{color:'red'}}>Sign Exist</p></>)
           : (signError===false ?(<><p style={{color:'green'}}>New Sign</p></>)  : 
           (<></>))}            
            {/* <div>
            <TextField
              // autoFocus
              margin="dense"
              id="synonyms"
              label="Synonyms"
              type="text"
              //fullWidth
              //style={{marginLeft:'70px'}}
            />
          </div> */}

          <SeeAlso  relatedSigns={relatedSigns} onChange={setRelatedSigns}/>

          <TextField
            autoFocus
            margin="dense"
            id="videourl"
            label="Video Url"
            value={url}
            type="text"
            fullWidth
            onChange={(event) => 
              {
                //setUrl(event.target.value)
                console.log(event.target.value,urlError);
                if (validUrl.isUri(event.target.value)) {
              setUrlError(false);
              setUrl(event.target.value);
             // console.log(event.target.value,url,event.target.value.length);
              
            } else if ((event.target.value.length)< 1) {

              setUrl(event.target.value);
              setUrlError(null);
            } else {
              setUrlError(true);
              setUrl(event.target.value);
               
            }
        }}
            
            //setUrl(event.target.value)}
            error={urlError}
          />
          <div style={{ marginTop: "20px" }}>
            {urlError === true ? (
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  color: "red",
                }}
              >
                <ClearIcon />
                <span>Invalid URL</span>
              </div>
            ) : urlError === false ? (
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  color: "green",
                }}
              >
                {" "}
                <CheckIcon />
                <span>Valid URL</span>
              </div>
            ) : (
              <></>
            )}
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
            >
            <TextField
              // autoFocus
              margin="dense"
              id="descrption"
              label="Description"
              type="text"
              value={description}
              onChange={(e)=>setDescription(e.target.value)}
              //fullWidth
              //style={{marginLeft:'70px'}}
            />{" "}
            <TextField
              // autoFocus
              margin="dense"
              id="remarks"
              label="Remarks"
              type="text"
              value={remarks}
              onChange={(e)=>setRemarks(e.target.value)}
              //fullWidth
              style={{ marginLeft: "70px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
       
        </DialogActions>
      </Dialog>
      <Snackbar open={snackBarOpen} anchorOrigin={ {vertical: 'top',
          horizontal: 'center',}} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          Added Successfully!
        </Alert>
      </Snackbar>

    </div>
  );
}
