import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../Stylesheet/alignment.css"
import LineTo from "react-lineto";
import { useHistory } from 'react-router-dom'
import LinkIcon from '@material-ui/icons/Link';
import RefreshIcon from '@material-ui/icons/Refresh';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams } from 'react-router-dom'
import { BIBLES_ABBRV_INDEX } from '../Tokenization/BCVDropdownComponents/BooksOfTheBible'
import { base_url } from "../../Services/ApiUrl"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const AlignmentEditor = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [data, setData] = useState([]);
    const [line, setLine] = useState(false)
    const [link, setLink] = useState([])
    const [dictionaryData, setDictionaryData] = useState([])
    const [isSuggestionDone, setIsSuggestionDone] = useState(false);
    const [translation, setTranslation] = useState([])
    const [altSuggestions, setAltSuggestions] = useState([])
    const [alternativeWord, setAlternativeWord] = useState("")
    const [toggleApproveButton,setToogleApproveButton] = useState(false)
    const sourceLang = props.sourceLang;
    const bookName = props.bookid;
    const chapter = props.chapter.padStart(3, '0');
    const verse = props.verse.padStart(3, '0');
    const BookCode = BIBLES_ABBRV_INDEX[bookName];
    const toVerse = props.toVerse.padStart(3, '0')
    const fromSentenceId = parseInt((BookCode + chapter + verse));
    const toSentenceId = parseInt((BookCode + chapter + toVerse));
    const projectid = props.projectId
    useEffect(() => {
    }, [props.toVerse])
    useEffect(() => {
    }, [props.verse])
    useEffect(() => {
    }, [bookName])
    useEffect(() => {
        props.setloading(true)
        getData();
        getDictionaryWord();
    }, [toSentenceId,chapter,fromSentenceId])
    useEffect(()=>{
    },[altSuggestions.length])
    useEffect(() => {
        if (source.length === 0) return;
        let url = `${base_url}nlp/gloss?source_language=${props.sourceCode}&target_language=${props.targetCode}&token=${source[0].name}`
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'app': 'Autographa'
            }
        }).then((item) => {
            setAltSuggestions(Object.keys(item.data.translations))
        }).catch((err) => {
        })
    }, [source.length])

    const getData = () => {
        fetch(`${base_url}autographa/project/suggestions?project_id=${projectid}&sentence_id_range=${fromSentenceId}&sentence_id_range=${toSentenceId}`,
            {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token') }`,
                    "app": "Autographa"
                }
            }).then(response => response.json())
            .then((item) => {
                let word = {}
                let wordArr = []
                let sourceArr = []
                let sourceLink = {}
                for (let loop1 = 0; loop1 < item.length; loop1++) {
                    if (item[loop1].sentenceId >= fromSentenceId && item[loop1].sentenceId <= toSentenceId) {
                        let selectedObj = item[loop1]
                        for (let loop2 = 0; loop2 < selectedObj.draftMeta.length; loop2++) {
                            if (selectedObj.draftMeta[loop2][2] !== "confirmed" && selectedObj.draftMeta[loop2][2] !== "suggestion") {
                                word = {
                                    "token": selectedObj.sentence.slice(selectedObj.draftMeta[loop2][0][0], selectedObj.draftMeta[loop2][0][1]),
                                    "occurrences": [
                                        {
                                            "sentenceId": selectedObj.sentenceId,
                                            "offset": [
                                                selectedObj.draftMeta[loop2][0][0],
                                                selectedObj.draftMeta[loop2][0][1]
                                            ]
                                        }
                                    ],
                                    "translation": "",
                                    "class": "token",
                                    "isSuggestion": selectedObj.draftMeta[loop2][2] === "suggestion" ? 1 : 0
                                }
                            } else {
                                sourceLink = {
                                    "source": [`source${loop2}`],
                                    "target": [`target${loop2}`],
                                    "token": selectedObj.sentence.slice(selectedObj.draftMeta[loop2][0][0], selectedObj.draftMeta[loop2][0][1]),
                                    "occurrences": [
                                        {
                                            "sentenceId": selectedObj.sentenceId,
                                            "offset": [
                                                selectedObj.draftMeta[loop2][0][0],
                                                selectedObj.draftMeta[loop2][0][1]
                                            ]
                                        }
                                    ],
                                    "translation": [selectedObj.draft.slice(selectedObj.draftMeta[loop2][1][0], selectedObj.draftMeta[loop2][1][1])],
                                    "isSuggestion": selectedObj.draftMeta[loop2][2] === "suggestion" ? 1 : 0
                                }
                                word = {
                                    "token": selectedObj.sentence.slice(selectedObj.draftMeta[loop2][0][0], selectedObj.draftMeta[loop2][0][1]),
                                    "occurrences": [
                                        {
                                            "sentenceId": selectedObj.sentenceId,
                                            "offset": [
                                                selectedObj.draftMeta[loop2][0][0],
                                                selectedObj.draftMeta[loop2][0][1]
                                            ]
                                        }
                                    ],
                                    "translation": [selectedObj.draft.slice(selectedObj.draftMeta[loop2][1][0], selectedObj.draftMeta[loop2][1][1])],
                                    "class": "token set-token",
                                    "isSuggestion": selectedObj.draftMeta[loop2][2] === "suggestion" ? 1 : 0
                                }
                                sourceArr = [
                                    ...sourceArr,
                                    sourceLink
                                ]
                            }
                            wordArr = [
                                ...wordArr,
                                word
                            ]
                        }
                        setData(wordArr)
                        setLink(sourceArr)
                    }
                }
                props.setloading(false)
            })
            .catch((error) => { console.log("getData=> ",error); })
    }
    const getDictionaryWord = () => {
        let url = `${base_url}dictionaries/ins_IRV_1_dictionary`
        axios.get(url, {
            headers: {
                'app': 'Autographa',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((item) => {
            setDictionaryData(item.data)
        }).catch((error) => {
        })
    }

    const addSource = (e, index) => {
        let id = e.target.id;
        let name = e.target.innerHTML;
        let selectedindex = id.match(/\d+/)[0];
        if(data[selectedindex].isSuggestion===1)
        {
            setToogleApproveButton(true)
        }else{
            setToogleApproveButton(false)
        }
        let selectedElement = document.getElementById(id).classList
        if (!selectedElement.contains("not-allowed-token")) {
            if (selectedElement.contains("set-token")) {
                setSource([])
                setTarget([])
                let sourceArr = [];
                let targetArr = [];
                let findSelectedIndex = link.findIndex(ele=>ele.source[0]===id)
                setSource([
                    {
                        "id": link[findSelectedIndex].source[0],
                        "name": link[findSelectedIndex].token
                    }
                ])
                setTarget([
                    {
                        "id": link[findSelectedIndex].target[0],
                        "name": link[findSelectedIndex].translation[0]
                    }
                ])
                document.getElementById(link[findSelectedIndex].source[0]).classList.add("active-token")
                document.getElementById(link[findSelectedIndex].target[0]).classList.add("active-token")
                let item = document.getElementsByClassName("token")
                for (let i = 0; i < item.length; i++) {
                    document.getElementById(item[i].id).classList.add("not-allowed-token")
                }
                document.getElementById("unlinkButton").style.cursor = "pointer"
                document.getElementById("refreshButton").style.cursor = "pointer"
            } else if (selectedElement.contains("active-token")) {
                setSource(source.filter(element=>element.id!==id))
                document.getElementById(id).classList.remove("active-token")
            } else {
                document.getElementById("linkButton").style.cursor = "pointer"
                document.getElementById("refreshButton").style.cursor = "pointer"
                document.getElementById(id).classList.add("active-token")
                setSource([
                    ...source, {
                        "id": id,
                        "name": name
                    }
                ])
            }
        }
    }
    const addTarget = (e) => {
        let id = e.target.id;
        let name = e.target.innerHTML;
        let type = e.target.nodeName;
        let selectedindex = id.match(/\d+/)[0];
        console.log(data[selectedindex])
        if(data[selectedindex].isSuggestion==1)
        {
            setToogleApproveButton(true)
        }else{
            setToogleApproveButton(false)
        }
        let selectedElement = document.getElementById(id).classList
        if (!selectedElement.contains("not-allowed-token")) {
            if (selectedElement.contains("set-token")) {
                setSource([])
                setTarget([])
                let sourceArr = [];
                let targetArr = [];
                let findSelectedIndex = link.findIndex(ele=>ele.target[0]===id)
                setSource([
                    {
                        "id": link[findSelectedIndex].source[0],
                        "name": link[findSelectedIndex].token
                    }
                ])
                setTarget([
                    {
                        "id": link[findSelectedIndex].target[0],
                        "name": link[findSelectedIndex].translation[0]
                    }
                ])
                document.getElementById(link[findSelectedIndex].source[0]).classList.add("active-token")
                document.getElementById(link[findSelectedIndex].target[0]).classList.add("active-token")
                let item = document.getElementsByClassName("token")
                for (let i = 0; i < item.length; i++) {
                    document.getElementById(item[i].id).classList.add("not-allowed-token")
                }
                document.getElementById("unlinkButton").style.cursor = "pointer"
                document.getElementById("refreshButton").style.cursor = "pointer"
            } else if (selectedElement.contains("active-token")) {
                setTarget(target.filter(element=>element.id!==id))
                document.getElementById(id).classList.remove("active-token")
            } else {
                document.getElementById("linkButton").style.cursor = "pointer"
                document.getElementById("refreshButton").style.cursor = "pointer"
                document.getElementById(id).classList.add("active-token")
                setTarget([
                    ...target, {
                        "id": id,
                        "name": name
                    }
                ])
            }
        }
    }
    const linkHandler = () => {
        let token = ""
        let occur = ""
        let translate = ""
        if (source.length !== 0 && target.length !== 0) {
            if (source.length === 1) {
                let sourceArray = [];
                let targetArray = [];
                let sourceIndex = source[0].id.match(/\d+/)[0];
                token = data[sourceIndex].token
                occur = data[sourceIndex].occurrences
                document.getElementById(source[0].id).classList.remove("active-token")
                document.getElementById(source[0].id).classList.add("set-token")
                let newArr = []
                let arr = []
                if(target.length===1)
                {
                    let targetIndex = target[0].id.match(/\d+/)[0];
                    translate = data[targetIndex].translation
                    document.getElementById(target[0].id).classList.remove("active-token")
                    document.getElementById(target[0].id).classList.add("set-token")
                    newArr = [{
                        "token": token,
                        "translation": translate,
                        "occurrences": occur,
                        "source": [source[0].id],
                        "target": [target[0].id]
                    }]
                    arr = link.concat(newArr)
                    setSource([])
                    setTarget([])
                    setAltSuggestions([])
                    setAlternativeWord("")
                }else{
                    let tempData = data
                    target.forEach((element,index)=>{
                        let tarIndex = element.id.match(/\d+/)[0]
                        translate += `${data[tarIndex].translation} `
                        tempData[tarIndex] = {}
                    })
                    tempData = tempData.filter(ele=>Object.keys(ele).length!=0)
                    let tempSourceIndex = source[0].id.match(/\d+/)[0]
                    tempData[tempSourceIndex].translation=[translate.trimEnd()]
                    tempData[tempSourceIndex].class="token set-token"
                    setData(tempData)
                    newArr = [{
                        "token": token,
                        "translation": [translate.trimEnd()],
                        "occurrences": occur,
                        "source": [source[0].id],
                        "target": [`target${tempSourceIndex}`],
                        "isSuggestion":0
                    }]
                    arr = link.concat(newArr)
                }
                setLink(arr)
                setSource([])
                setTarget([])
                setAltSuggestions([])
                setAlternativeWord("")
                document.getElementById("refreshButton").style.cursor = "not-allowed"
                document.getElementById("linkButton").style.cursor = "not-allowed"
            } else {
                let min = 0;
                let max = 0;
                source.forEach((element, idx) => {
                    let index = element.id.match(/\d+/)[0]
                    token += data[index].token
                    if (idx == 0) {
                        min = data[index].occurrences[0].offset[0]
                    }
                    if (idx == (source.length - 1)) {
                        max = data[index].occurrences[0].offset[1]
                    }
                    document.getElementById(element.id).classList.remove("active-token")
                    document.getElementById(element.id).classList.add("set-token")
                });
                occur = [
                    {
                        "sentenceId": data[0].occurrences[0].sentenceId,
                        "offset": [
                            min,
                            max
                        ]
                    }
                ]
                let newData = data;
                let index = source[0].id.match(/\d+/)[0]
                let targetIndex = target[0].id.match(/\d+/)[0]
                targetIndex = (targetIndex - (source.length-1))-1
                newData.splice(index,source.length)
                newData.splice(targetIndex,1)
                let newWord = {
                    "token": token,
                    "occurrences": occur,
                    "translation": [target[0].name],
                    "class": "token set-token",
                    "isSuggestion": 0
                }
                newData.splice(index, 0, newWord);
                document.getElementById(target[0].id).classList.remove("active-token")
                document.getElementById(target[0].id).classList.add("set-token")
                let newArr = [{
                    "token": token,
                    "translation": [target[0].name],
                    "occurrences": data[index].occurrences,
                    "source": [`source${index}`],
                    "target": [`target${index}`],
                    "isSuggestion": 0
                }]
                let arr = link.concat(newArr)
                let newSourceIndex = index
                let differenceLength = source.length-1
                console.log("daaaa->",data,"\n",link)
                for (let i = 0; i < arr.length; i++) {
                    let index = arr[i].source[0].match(/\d+/)[0]
                    if (parseInt(index) > parseInt(newSourceIndex)) {
                        let newIndex = parseInt(index) - differenceLength
                        let tempSourceIndex = arr[i].source[0].match(/\d+/)[0]
                        let tempTargetIndex = arr[i].target[0].match(/\d+/)[0]
                        if(tempSourceIndex==tempTargetIndex)
                        {
                            arr[i].source[0] = `source${newIndex}`
                            arr[i].target[0] = `target${newIndex}`
                        }else{
                            arr[i].source[0] = `source${newIndex}`
                        }
                    }
                    let tempSourceIndex = arr[i].source[0].match(/\d+/)[0]
                    let tempTargetIndex = arr[i].target[0].match(/\d+/)[0]
                    if(tempSourceIndex!=tempTargetIndex)
                    {
                        console.log("a",arr[i])
                        let tempIndex = parseInt(arr[i].target[0].match(/\d+/)[0])
                        tempIndex-=differenceLength
                        arr[i].target[0] = `target${tempIndex}`
                        newData[parseInt(tempIndex)].class = "token set-token"
                        console.log(link,data)
                    }
                    if(parseInt(index) < parseInt(newSourceIndex)){
                        newData[parseInt(index)].class = "token set-token"
                    }else{
                        newData[parseInt(index)-differenceLength].class = "token set-token"
                    }
                }
                setData(newData)
                setLink(arr)
                setSource([])
                setTarget([])
                setAltSuggestions([])
                setAlternativeWord("")
                document.getElementById("refreshButton").style.cursor = "not-allowed"
                document.getElementById("linkButton").style.cursor = "not-allowed"
            }
        } else {
            alert("please select target & source")
        }
        setSource([])
          setTarget([])
          setAltSuggestions([])
          setAlternativeWord("")
    }
    const unlinkHandler = () => {
        let sourceIndex = source[0].id.match(/\d+/)[0]
        data[sourceIndex].class = "token"
        data[sourceIndex].translation = ""
        data[sourceIndex].isSuggestion = 0
        setLink(link.filter(element=>element.source[0]!=source[0].id))
        for (let i = 0; i < source.length; i++) {
            document.getElementById(source[i].id).classList.remove("set-token")
            document.getElementById(source[i].id).classList.remove("active-token")
        }
        for (let i = 0; i < target.length; i++) {
            document.getElementById(target[i].id).classList.remove("set-token")
            document.getElementById(target[i].id).classList.remove("active-token")
        }
        refreshState()
        let item = document.getElementsByClassName("token")
        for (let i = 0; i < item.length; i++) {
            document.getElementById(item[i].id).classList.remove("not-allowed-token")
        }
        setSource([]);
        setTarget([]);
        setAltSuggestions([])
        setAlternativeWord("")
        refreshState()
        document.getElementById("linkButton").style.cursor = "not-allowed"
        document.getElementById("unlinkButton").style.cursor = "not-allowed"
        document.getElementById("refreshButton").style.cursor = "not-allowed"

    }
    const refreshState = () => {
        setSource([])
        setTarget([])
        setAltSuggestions([])
        setAlternativeWord("")
        setToogleApproveButton(false)
        let item = document.getElementsByClassName("token")
        for (let i = 0; i < item.length; i++) {
            if (document.getElementById(item[i].id)?.classList?.contains("not-allowed-token")) {
                document.getElementById(item[i].id)?.classList?.remove("not-allowed-token")
            }
            document.getElementById(item[i].id).classList.remove("active-token")
        }
        document.getElementById("unlinkButton").style.cursor = "not-allowed"
        document.getElementById("refreshButton").style.cursor = "not-allowed"
        document.getElementById("linkButton").style.cursor = "not-allowed"
    }
    const addWord = () => {
        let enteredData = {
            "token": "",
            "class": "token",
            "translation": [translation],
            "isSuggestion": 0
        }
        setData([
            ...data,
            enteredData
        ])
        setTranslation("")
    }
    const submitTranslation = () => {
        let linkList = link
        props.setloading(true)
        linkList = linkList.filter(element=>element.isSuggestion!==1)
        for (let i = 0; i < linkList.length; i++) {
            if (linkList[i].occurrences.length > 1) {
            }
            delete linkList[i].isSuggestion
            delete linkList[i].source;
            delete linkList[i].target;
            linkList[i].translation = linkList[i].translation[0];
        }
        console.log(linkList)
        axios.put(`${base_url}autographa/project/tokens?project_id=${projectid}`, linkList, {
            headers: {
                "app": "Autographa",
                "content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')
                    }`
            }
        }).then((item) => {
            alert("translation saved");
            refreshState();
            setSource([])
            setTarget([])
            setData([])
            setLink([])
            setDictionaryData([])
            setIsSuggestionDone(false)
            setTranslation([])
            setAltSuggestions([])
            setAlternativeWord("")
            props.setloading(false)
            getData()
            getDictionaryWord()
        }).catch((error) => {
            props.setloading(false)
        })
    }
    function getWordCount(str) {
        let arrLength = str.split(' ')
            .filter(function (n) { return n != '' })
            .length;
        if (arrLength > 1) {
            return str.split(' ')
                .filter(function (n) { return n != '' })
        }
    }
    const splitToken = (index) => {
        props.setloading(true)
        if (getWordCount(data[index].token) != undefined) {
            refreshState();
            const arr = data[index].token.split(" ")
            let startingPoint = data[index].occurrences[0].offset[0]
            let splitedArr = []
            let splitobj = {}
            for (let i = 0; i < arr.length; i++) {
                let endingPoint = startingPoint + arr[i].length
                splitobj = {
                    "token": arr[i],
                    "occurrences": [
                        {
                            "sentenceId": data[index].occurrences[0].sentenceId,
                            "offset": [
                                startingPoint,
                                endingPoint
                            ]
                        }
                    ],
                    "translation": "",
                    "class": "token",
                    "isSuggestion": 0
                }
                startingPoint = endingPoint + 1;
                splitedArr = [...splitedArr, splitobj]
            }
            split(index, splitedArr)
        } else {
            props.setloading(false)
            alert("word is not available to split")
        }
    }
    const split = (id, newArr) => {
        let newData = [];
        for (let i = 0; i < data.length; i++) {
            if (i === id) {
                for (let j = 0; j < newArr.length; j++) {
                    newData = [...newData, newArr[j]]
                }
            } else {
                newData = [...newData, data[i]]
            }
        }
        let newLink = link;
        for (let i = 0; i < newLink.length; i++) {
            let index = newLink[i].source[0].match(/\d+/)[0]
            let temp = newArr.length - 1
            if (index == id) {
                newLink.splice(i, 1)
            }
            else if (index > id) {
                let newIndex = parseInt(index) + parseInt(temp)
                let tempSourceIndex = newLink[i].source[0].match(/\d+/)[0]
                let tempTargetIndex = newLink[i].target[0].match(/\d+/)[0]
                if(tempSourceIndex==tempTargetIndex)
                {
                    newLink[i].source[0] = `source${newIndex}`
                    newLink[i].target[0] = `target${newIndex}`
                }else{
                    newLink[i].source[0] = `source${newIndex}`
                }
            }
            let tempSourceIndex = newLink[i].source[0].match(/\d+/)[0]
            let tempTargetIndex = newLink[i].target[0].match(/\d+/)[0]
            if(tempSourceIndex!=tempTargetIndex)
            {
                let tempIndex = parseInt(newLink[i].target[0].match(/\d+/)[0])
                tempIndex+=1
                newLink[i].target[0] = `target${tempIndex}`
                newData[parseInt(tempIndex)].class = "token set-token"
                console.log(link,data)
            }
            if(index < id){
                newData[parseInt(index)].class = "token set-token"
            }else{
                newData[parseInt(index)+1].class = "token set-token"
            }
        }
        console.log("data=>",newData,newLink)
        setData(newData)
        setLink(newLink)
        props.setloading(false)
    }
    const splitTargetToken = (index) =>{
        const wordCount = getWordCount(data[index].translation[0])
        const word = data[index].translation[0].trimEnd()
        document.getElementById(`source${index}`).style.boxShadow = "0px 0px"
        if(wordCount==undefined)
        {
            alert("word not available for split")
            return;
        }
        let tempData = data
        tempData[index].class = "token"
        tempData[index].translation = ""
        setLink(link.filter(element=>element.target[0]!==`target${index}`))
        const wordArr = word.split(" ")
        wordArr.forEach(element=>{
            let enteredData = {
                "token": "",
                "class": "token",
                "translation": [element],
                "isSuggestion": 0
            }
            tempData.push(enteredData)
        })
        setData(tempData)
        setSource([])
        setTarget([])
    }
    const handleAlternativeSuggestions = (e) => {
        let index = source[0].id.match(/\d+/)[0]
        let tempData = data
        tempData[index].translation = [e.target.innerHTML]
        setData(tempData)
        let tempTarget = target
        tempTarget[0].name = e.target.innerHTML
        setTarget(tempTarget)
        let tempLink = link
        const linkIndex = tempLink.findIndex(tempLink => tempLink.source[0] == source[0].id);
        tempLink[linkIndex].translation[0] = e.target.innerHTML
        setLink(tempLink)
        setAlternativeWord(e.target.innerHTML)
    }
    const handleMouseEnter = (e, text) => {
        if (document.getElementById(e.target.id).classList.contains("set-token")) {
            const index = text === 'source' ? link.findIndex(ele => ele.source[0] == e.target.id) : link.findIndex(ele => ele.target[0] == e.target.id)
            document.getElementById(link[index].target[0]).style.boxShadow = "0 0 1pt 3pt darkblue"
            document.getElementById(link[index].source[0]).style.boxShadow = "0 0 1pt 3pt darkblue"
        }
    }
    const handleMouseLeave = (e, text) => {
        if (document.getElementById(e.target.id).classList.contains("set-token")) {
            const index = text === 'source' ? link.findIndex(ele => ele.source[0] == e.target.id) : link.findIndex(ele => ele.target[0] == e.target.id)
            document.getElementById(link[index].target[0]).style.boxShadow = "none"
            document.getElementById(link[index].source[0]).style.boxShadow = "none"
        }
    }
    const approveSuggestion = () =>{
        let index = source[0].id.match(/\d+/)[0];
        console.log("data",data[index],source[0].id)
        setData(
            data.filter((element,i)=>{
                if(i==index)
                {
                  element.isSuggestion=0
                  return element
                }else{
                    return element
                }
            })
        )
        setLink(link.filter(element=>{
                if(element.source[0]==source[0].id)
                {
                    element.isSuggestion=0
                    return element
                }else{
                    return element
                }
        }))
        refreshState();
    }
    return (
        <>
            <div className="container">
                <div className="alignment-container">
                    <div className="alignmentBox1">
                        <div className="source-box">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <h3 style={{ width: '80%' }}>{sourceLang} Bible Text</h3>
                            </div>
                            <div></div>
                            <div> {
                                data.map((item, index) => {
                                    if (item.token !== "") {
                                        return (
                                            <span
                                                onMouseEnter={(e) => { handleMouseEnter(e, 'source') }}
                                                onMouseLeave={(e) => { handleMouseLeave(e, 'source') }}
                                                className={
                                                    item.class
                                                }
                                                style={{  backgroundColor: document?.getElementById(`source${index}`)?.classList?.contains('active-token') ? '#2649fc' : item.isSuggestion === 1 ? '#c4a35a' : null}}
                                                id={
                                                    `source${index}`
                                                }
                                                onDoubleClick={() => { splitToken(index) }}
                                                onClick={
                                                    (e) => {
                                                        addSource(e, index)
                                                    }
                                                }>
                                                {
                                                    item.token
                                                }</span>
                                        )
                                    }
                                })
                            } </div>
                        </div>
                        <div className="target-box">
                            <h3>Sign Token Sequence</h3>
                            <div className="target-suggestions">
                                {
                                    data.map((item, index) => {
                                        if (item.translation !== "") {
                                            if (item.translation.length === 1) {
                                                return (
                                                    <span className={
                                                        item.class
                                                    }
                                                    onDoubleClick={() => { splitTargetToken(index) }}
                                                        id={
                                                            `target${index}`
                                                        }
                                                        onMouseEnter={(e) => { handleMouseEnter(e, 'target') }}
                                                        onMouseLeave={(e) => { handleMouseLeave(e, 'target') }}
                                                        style={{  backgroundColor: document?.getElementById(`target${index}`)?.classList?.contains('active-token') ? '#2649fc' : item.isSuggestion === 1 ? '#c4a35a' : null }}
                                                        onClick={
                                                            (e) => {
                                                                addTarget(e, index)
                                                            }
                                                        }>
                                                        {
                                                            item.translation
                                                        } </span>
                                                )
                                            } else {
                                                return (
                                                    <select defaultValue={
                                                        item.translation[0]
                                                    }
                                                        className={
                                                            item.class
                                                        }
                                                        id={
                                                            `target${index}`
                                                        }
                                                        onChange={
                                                            (e) => {
                                                                addTarget(e, index)
                                                            }
                                                        }>
                                                        {
                                                            item.translation.map((item, index) => {
                                                                return (
                                                                    <option value={item}
                                                                        key={index}>
                                                                        {item}</option>
                                                                )
                                                            })
                                                        } </select>
                                                )
                                            }
                                        }
                                    })
                                } </div>
                        </div>
                    </div>
                    <div className="alignmentBox2">
                        <div className="source-box1">
                            {
                                source.map((item, index) => {
                                    return (
                                        <span className="token line0"
                                            id={
                                                `setsource${index}`
                                            }>
                                            {
                                                item.name
                                            }</span>
                                    )
                                })
                            } </div>
                    </div>
                </div>
                <div className="control-panel">
                    <div className="control-panel-btn">
                        <div className="final-submission-button">
                            <Autocomplete
                                value={translation}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setTranslation(newValue.inputValue);
                                    } else if (newValue && newValue.inputValue) {
                                        axios.post(`${base_url}dictionaries/ins_IRV_1_dictionary`,
                                            [
                                                {
                                                    "word": newValue.inputValue,
                                                    "details": {
                                                        "videoUrl": "",
                                                        "synonyms": "",
                                                        "description": "",
                                                        "relatedSigns": [],
                                                        "remarks": ""
                                                    },
                                                    "active": true
                                                }
                                            ]
                                            , {
                                                headers: {
                                                    'app': 'Autographa',
                                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                                }
                                            }).then((data) => {
                                                setTranslation(newValue.inputValue);
                                                getDictionaryWord()
                                            }).catch((error) => {
                                            })
                                    } else {
                                        setTranslation(newValue.word);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            word: `Create "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={dictionaryData}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.word;
                                }}
                                renderOption={(option) => option.word}
                                style={{ width: 200, marginTop: -15 }}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label="Enter Translation" variant="filled" />
                                )}
                            />
                            <Avatar style={
                                { marginLeft: "10px", marginTop: -10 }
                            }
                                onClick={
                                    () => {
                                        addWord()
                                    }
                                }>
                                <SendIcon />
                            </Avatar>
                        </div>
                    </div>

                    <div className="final-submission-button" style={{ display: 'flex', flexDirection: 'column', height: 80, marginTop: -20, width: 270 }}>
                        <p style={{ margin: '0px', padding: "0px" }}>Alternative Suggestions</p>
                        <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', width: 350 }}>
                            {
                                altSuggestions.map((ele) => {
                                    return (
                                        <span
                                            onClick={(e) => { handleAlternativeSuggestions(e) }}
                                            className='token'>
                                            {ele}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        toggleApproveButton?
                        <ThumbUpIcon fontSize="large"
                        onClick={
                            () => {
                                approveSuggestion() //linkHandler()
                            }
                        }
                        id="approveButton"
                        style={
                            { cursor: "pointer" }
                        } />
                        :
                        <LinkIcon fontSize="large"
                        onClick={
                            () => {
                                linkHandler()
                            }
                        }
                        id="linkButton"
                        style={
                            { cursor: "not-allowed" }
                        } />
                    }
                    <LinkOffIcon fontSize="large"
                        onClick={
                            () => {
                                unlinkHandler();
                            }
                        }
                        id="unlinkButton"
                        style={
                            { cursor: "not-allowed" }
                        } />
                    <RefreshIcon fontSize="large" id="refreshButton"
                        style={
                            { cursor: "not-allowed" }
                        }
                        onClick={
                            () => {
                                refreshState()
                            }
                        } />
                    <div className="final-submission-button">
                        <Button variant="contained" color="primary"
                            onClick={
                                () => {
                                    submitTranslation()
                                }
                            }>
                            Final Submission
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AlignmentEditor;
