import { useEffect, useRef } from "react";
import axios from "axios";
import tsvToJson from "../tsvToJson";
import { useState } from "react";
import { TN_FILENAMES } from "../BCVDropdownComponents/BooksOfTheBible";

const TranslationNotes = (props) => {
  var showdown = require("showdown");
  var converter = new showdown.Converter();
  const parse = require("html-react-parser");
  const bookId = props.bookId;
  const chapter = props.chapter;
  const verse = props.verse;
  const toVerse = props.toVerse;
  const [notes, setNotes] = useState([]);
  const [verseNotes, setVerseNotes] = useState([]);
  const textRef = useRef();
  const tnfilename = TN_FILENAMES[bookId];

  const API = axios.create({
    baseURL: "https://git.door43.org/unfoldingWord",
  });

  useEffect(() => {
    const fetchData = () => {
      API.get(`/en_tn/raw/branch/master/en${tnfilename}.tsv`)
        .then((res) => {
          const t = tsvToJson(res.data);
          setNotes(t);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [tnfilename]);

  useEffect(() => {
    let arr = notes;
    let x = arr.filter((a) => {
      if (
        parseInt(a.Chapter) === parseInt(chapter) &&
        parseInt(a.Verse) >= parseInt(verse) &&
        parseInt(a.Verse) <= parseInt(toVerse)
      ) {
        return a;
      }
    });

    setVerseNotes(x);
  }, [chapter, verse, notes, toVerse]);

  useEffect(() => {
    //Scroll to top on text change
    if (textRef.current !== undefined && textRef.current !== null)
      textRef.current.scrollTo(0, 0);
  }, [bookId, chapter, verse, toVerse]);

  return (
    <div style={{ height: "250px" }}>
      <div
        style={{
          overflowY: "scroll",
          height: "92%",
          marginTop: "10px",
          background: "#f7f1e3",
          color: "#40407a",
        }}
        ref={textRef}
      >
        {verseNotes.map((note) => (
          <div
            className="note-preview"
            key={note.ID}
            style={{
              width: "95%",
              marginLeft: 10,
              marginRight: 10,
              display: "flex",
              flexWrap: "wrap",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <strong>Book</strong> {note.Book}
              </div>
              <div>
                <strong>Chapter</strong> {note.Chapter}{" "}
              </div>
              <div>
                <strong>Verse</strong> {note.Verse}
              </div>
            </div>
            <br />

            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{ marginRight: "10px" }}>
                <strong>GLQuote</strong> {note.GLQuote}
              </div>
              <div style={{ marginRight: "10px" }}>
                <strong>ID</strong> {note.ID}
              </div>
              <div style={{ marginRight: "10px" }}>
                <strong>OrigQuote</strong> {note.OrigQuote}
              </div>
              <div style={{ marginRight: "10px" }}>
                <strong>SupportReference</strong> {note.SupportReference}
              </div>
              <div>
                <strong>Occurrence</strong> {note.Occurrence}
              </div>
            </div>
            <br />
            <div>
              <strong>OccurrenceNote</strong>{" "}
              {parse(converter.makeHtml(note.OccurrenceNote))}
            </div>
            <div style={{ height: 30, width: "100%" }}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TranslationNotes;
