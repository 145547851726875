import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import SeeAlso from './SeeAlso';
import { base_url } from "../../Services/ApiUrl";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';




export default function EditSign(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedWord, setSelectedWord] = React.useState(props.selectedWord);
  const [synonyms, setSynonyms] = React.useState(props.synonyms);
  const [description, setDescription] = React.useState(props.description);
  const [initialVideoUrl, setInitialVideoUrl] = React.useState(props.videourl);
 // const [newVideoUrl, setNewVideoUrl] = React.useState('');
  const [relatedSigns, setRelatedSigns] = React.useState(props.relatedSigns);
  const [remarks, setRemarks] = React.useState(props.remarks);
  const [urlError, setUrlError] = React.useState(null);
  const validUrl = require("valid-url");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };
 
 
const handleSubmit=(e)=>{
e.preventDefault();
console.log(relatedSigns)
if( urlError!==true)
{
 // console.log(sign,synonyms,description,remarks,url,relatedSigns);
 const token = localStorage.getItem("token");

  fetch(`${base_url}dictionaries/ins_IRV_1_dictionary`, {
    method: "PUT",
    body: JSON.stringify([{
      "word": selectedWord,
      "details": {
        "videoUrl": initialVideoUrl,
        "synonyms": synonyms,
        "description": description,
        "relatedSigns": relatedSigns,
        "remarks": remarks
      },
      "active": true
    }]),
    headers: {
      "Content-type": "application/json",
       "Authorization": `Bearer ${token}`,
        "app": "Autographa"
    },
  })
    .then((response) => response.json())
    .then((json) => {
      //console.log(json);
      setSnackBarOpen(true);
      setOpen(false);
      window.location.reload();
    }) 
    .catch((error) => {
      console.error('Error:', error);
    });


}



}

  const handleClickOpen = () => {
    setSelectedWord(props.selectedWord);
    setSynonyms(props.synonyms);
    setDescription(props.description);
    setInitialVideoUrl(props.videourl);
    setUrlError(null);
    setRelatedSigns(props.relatedSigns);
    setRemarks(props.remarks);
    setOpen(true);
    
    //console.log(props.videourl)
  };

  const handleClose = () => {
    // setSelectedWord(props.selectedWord);
    // setSynonyms(props.synonyms);
    // setDescription(props.description);
    // setVideoUrl(props.videourl);
    // setRelatedSigns(props.relatedSigns);
    // setRemarks(PinDropSharp.remarks);


    setOpen(false);
  };

  return (
    <div>
        <Tooltip title="Edit Sign" aria-label="edit" placement="bottom">

     
      <IconButton
           // color="secondary"
            aria-label="Edit"
            onClick={handleClickOpen}
          >
            {/* <EditIcon style={{ color: blue[500] }} /> */}
            <EditIcon style={{ color: '#485460' }} />
          </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{color:'#ff4757'}}>Edit Sign Detail</DialogTitle>
        <DialogContent >
          {/* <DialogContentText>
          </DialogContentText> */}
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <TextField
            autoFocus
            margin="dense"
            id="sign"
            label="Sign"
            type="text"
            value={selectedWord}
            disabled
            //fullWidth
          /> 
          <TextField
         // autoFocus
          margin="dense"
          id="synonyms"
          label="Synonyms"
          value={synonyms}
          onChange={(e)=>{setSynonyms(e.target.value)}}
          type="text"
          //fullWidth
        //style={{marginLeft:'70px'}}
        /> 
        </div>
        
 <SeeAlso  relatedSigns={relatedSigns}  onChange={setRelatedSigns}/>
       <TextField
            autoFocus
            margin="dense"
            id="videourl"
            label="Video Url"
            type="text"
            value={initialVideoUrl}
            fullWidth
            onChange={(event) => {
              
                if (validUrl.isUri(event.target.value)) {
              setUrlError(false);
              setInitialVideoUrl(event.target.value);
             // console.log(event.target.value,url,event.target.value.length);
              
            } else if ((event.target.value.length)< 1) {

              setUrlError(null);
              setInitialVideoUrl(event.target.value);

            } else {
              setUrlError(true);
              setInitialVideoUrl(event.target.value);

               
            }
        }
      } 
      error={urlError}
          />
          <div style={{ marginTop: "20px" }}>
            {urlError === true ? (
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  color: "red",
                }}
              >
                <ClearIcon />
                <span>Invalid URL</span>
              </div>
            ) : urlError === false ? (
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  color: "green",
                }}
              >
                {" "}
                <CheckIcon />
                <span>Valid URL</span>
              </div>
            ) : (
              <></>
            )}
            </div>

        
          <div style={{display:'flex',justifyContent:'space-between'}}>
        <TextField
         // autoFocus
          margin="dense"
          id="descrption"
          label="Description"
          type="text"
          value={description}
          onChange={(e)=>setDescription(e.target.value)}
          //fullWidth
        //style={{marginLeft:'70px'}}
        /> <TextField
        // autoFocus
         margin="dense"
         id="remarks"
         value={remarks}
         label="Remarks"
         type="text"
         onChange={(e)=>setRemarks(e.target.value)}

         //fullWidth
       //style={{marginLeft:'70px'}}
       />
       </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackBarOpen} anchorOrigin={ {vertical: 'top',
          horizontal: 'center',}} autoHideDuration={3000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="success">
          Updated Successfully!
        </Alert>
      </Snackbar>
    </div>
  );
}
