import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select    from 'react-select';
import { base_url } from '../../../../Services/ApiUrl'
import { Box } from '@material-ui/core';
import axios from 'axios';

export default function SelectCommentaryVer(props) {
  const { width, value, onChange } = props;
  const [commentaryVersion, setCommentaryVersion] = useState();
  const [defaultVer, setDefaultVer] = useState(null);



  useEffect(() => {
    axios.get(`${base_url}sources?content_type=commentary&access_tag=publishable&latest_revision=False&&skip=0&limit=100`).then(function (response) {
      const com = response.data.map((item) => {
        const label =
         
        
            item.metaData.Abbreviation  !== undefined?(item.metaData["Language Code"]+" - "+item.metaData.Abbreviation): item.metaData.otherName

        return { value: item.sourceName, label: label };
      });
      let defValue = com[0];
      //if want default bible as any specfic commentary uncomment if
    //   if (
    //     bible.findIndex((b1) => b1.label === "commentary -version") !== -1
    //   ) {
    //     defValue = {
    //       value: "en_UNFOLDINGWORDLITERALTEXT_1_bible",
    //       label: "UNFOLDINGWORDLITERALTEXT",
    //     };
    //   }

      onChange(defValue);
      setDefaultVer(defValue);

      //setBibleVersion(bible);

      setCommentaryVersion(com);
      
    });

  }
  , []);
  return (
    <Box style={{ width: width || 300 }}>
    {commentaryVersion !== {} && commentaryVersion !== null &&
   
      <Select
        maxMenuHeight={190}
      onChange={(option) => onChange(option)}
        options={commentaryVersion}
        // getOptionValue={(option) => option.sourceName}
        // getOptionLabel={(option) =>

        //   (option.metaData.Abbreviation)?(option.metaData["Language Code"]+" - "+option.metaData.Abbreviation): option.metaData.otherName

        // }
       // defaultValue={"defaultVer"}
         placeholder={commentaryVersion ? props.componentName : 'Loading'}
        isSearchable={false}
        isClearable ={false}
        value={value?value:defaultVer}
        isLoading={!commentaryVersion}
        isDisabled={!commentaryVersion}
      />
}
    </Box>
  );
}

SelectCommentaryVer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  width: PropTypes.number,
  componentName: PropTypes.string,
};