// import QuickFilteringGrid from './DictionaryManagement/QuickFilter';
import QuickFilter from './DictionaryManagement/QuickFilter';
import QuickFilter3 from './DictionaryManagement/QuickFilter3';
import AddSign from './DictionaryManagement/AddSign';
import React from 'react';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';
import { useHistory } from 'react-router-dom';
// import Checkbox from '@material-ui/core/Checkbox';
// import { withStyles } from '@material-ui/core/styles';
// import { green } from '@material-ui/core/colors';


const DictionaryManagementPage = () => {
  const history=useHistory();
  const  {login}=useContext(AuthContext);
    const [state, setState] = React.useState({
        checkedEmptyVideoUrl: true,
        checkedSeeMore: false,
      });
      // const GreenCheckbox = withStyles({
      //   root: {
      //     color: green[400],
      //     '&$checked': {
      //       color: green[600],
      //     },
      //   },
      //   checked: {},
      // })((props) => <Checkbox color="default" {...props} />);

      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        console.log(state,event.target.name,event.target.checked);
      };


    return (  <>
        {login?<>
        <div style={{display:'flex',marginBottom:'10px',marginTop:'10px',justifyContent:'flex-start'}}>
          <div style={{marginLeft:'5%'}}> 
          <AddSign /></div></div>
        <div style={{display:'flex',width:'90%',marginLeft:'5%'}}>
        <div style={{marginLeft:'5%'}}>
        { state.checkedSeeMore===false?(
        <FormControlLabel
        control={<Switch checked={state.checkedEmptyVideoUrl} onChange={handleChange} name="checkedEmptyVideoUrl"  /> }
        label="Empty Video URL"
      />):(<></>)}</div>
        </div>
      
         <div className="App" style={{display:'flex',justifyContent:'center'}}>
          { state.checkedEmptyVideoUrl===false? <QuickFilter />:<><QuickFilter3 /></>}</div>

      
        </>:<>
        {history.push("/")}
      {alert("Access denied,Please Login")}
        
        </>}</>
    );
}
 
export default DictionaryManagementPage;