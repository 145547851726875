import { useEffect, useRef } from "react";
import axios from "axios";
import tsvToJson from "../tsvToJson";
import { useState } from "react";
import { TWL_FILENAMES } from "../BCVDropdownComponents/BooksOfTheBible";

const TranslationWords = (props) => {
  var showdown = require("showdown");
  var converter = new showdown.Converter();
  const parse = require("html-react-parser");

  const [data, setData] = useState([]);
  const [dat, setDat] = useState([]);
  const textRef = useRef();
  const bookId = props.bookId;
  const chapter = props.chapter;
  const verse = props.verse;
  const toVerse = props.toVerse;

  const twlfilename = TWL_FILENAMES[props.bookId];
  const API = axios.create({
    baseURL: "https://git.door43.org/unfoldingWord",
  });

  useEffect(() => {
    const fetchData = () => {
      API.get(`/en_twl/raw/branch/master/${twlfilename}.tsv`)
        .then((res) => {
          const t = tsvToJson(res.data);
          setDat(t);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [twlfilename]);
  useEffect(() => {
    //Scroll to top on text change
    if (textRef.current !== undefined && textRef.current !== null)
      textRef.current.scrollTo(0, 0);
  }, [bookId, chapter, verse, toVerse]);

  useEffect(() => {
    let datacv = dat
      .filter(
        (item) =>
          parseInt(item.Reference.substring(0, item.Reference.indexOf(":"))) ===
            parseInt(chapter) &&
          parseInt(item.Reference.substring(item.Reference.indexOf(":") + 1)) >=
            parseInt(verse) &&
          parseInt(item.Reference.substring(item.Reference.indexOf(":") + 1)) <=
            parseInt(toVerse)
      )
      .map((item) => {
        return {
          ID: item.ID,
          Occurrence: item.Occurrence,
          OrigWords: item.OrigWords,
          Reference: item.Reference,
          Tags: item.Tags,
          TWLink: item.TWLink,
        };
      });

    console.log("datacv", datacv);
    const dataList = [];

    datacv?.map((c, i) => {
      dataList.push(
        API.get(
          `/en_tw/raw/branch/master` +
            c.TWLink.split("rc://*/tw/dict")[1] +
            `.md`
        )
      );
    });
    Promise.all(dataList).then(function (values) {
      console.log(values);

      const newDataList = values.map((d, i) => {
        return {
          ID: i,
          data: d.data,
        };
      });
      setData(newDataList);
    });
  }, [chapter, verse, dat, toVerse]);

  return (
    <>
      <div style={{ height: "250px" }}>
        <div
          style={{
            overflowY: "scroll",
            height: "92%",
            marginTop: "10px",
            background: "#f7f1e3",
            color: "#40407a",
          }}
          ref={textRef}
        >
          <main>
            {data?.map((item) => (
              <div key={item.ID}> {parse(converter.makeHtml(item.data))} </div>
            ))}
            {console.log(data, data.length)}
          </main>
        </div>
      </div>
    </>
  );
};
export default TranslationWords;
