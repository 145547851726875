import SignUpAvatar from "./SignUpAvatar";
import { TextField,makeStyles,Button,Grid,Box,withStyles } from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import {useHistory} from 'react-router-dom'
import Tooltip from "@material-ui/core/Tooltip";
import { base_url } from "../../Services/ApiUrl";


const useStyle = makeStyles({
  box:{
    maxWidth: "500px",
    margin: "0px auto"
  }
});
const LightTooltip = withStyles((theme) => ({
  tooltip: {

    fontSize: 12,
  },
}))(Tooltip);

const SignUp = (props) => {
  const classes = useStyle();
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [firstNameError,setFirstNameError] = useState(false)
  const [lastNameError,setLastNameError] = useState(false)
  const [emailError,setEmailError] = useState(false)
  const [passwordError,setPasswordError] = useState(false)
  const [pending,setPending] = useState(false)
  const history=useHistory();
  const validPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})");
  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const handleSubmit = (e) =>{
    e.preventDefault();
    setPending(true)
    setFirstNameError(false)
    setLastNameError(false)
    setEmailError(false)
    setPasswordError(false)

    if(firstName==='')
    {
      setFirstNameError(true)
      setPending(false)
    }
    else if(lastName==='')
    {
      setLastNameError(true)
      setPending(false)
    }
    else if(email==='')
    {
      setEmailError(true)
      setPending(false)
    } else if((email.length>0)&&(!validEmail.test(email)))
    {
      setEmailError(true)
      alert("Your email is invalid");
      setPending(false)
    }
    else if(password==='')
    {
      setPasswordError(true)
      setPending(false)
    }else if((password.length>0) && (!validPassword.test(password))) {
      
        setPasswordError(true);
        alert("Password does'nt match the criteria");
        setPending(false)
    }
    else{
      const data = {
        firstname: firstName,
        email: email,
        password: password,
        lastname: lastName
      }
      axios.post(`${base_url}user/register?app_type=Autographa`,data,{
        headers:{
          'content-type': 'application/json'
        }
      }).then((item)=>{
        alert("Sign Up Successful")
        // localStorage.setItem('login',true);
        // localStorage.setItem('token',item.data.token);
        // props.loginStatus(true)
        setFirstName('');
        setEmail('');
        setPassword('');
        setLastName('');
        history.push("/login")
      }).catch((error)=>{
        if(error.response.status===400)
        {
          alert("You're Already Registered. Click to go on Login Page")
          history.push("/login")
        }else if(error.response.status===402)
        {
          alert("Please use a strong password")
          setPending(false)
        }else{
          alert(error.response.data.details)
         // console.log(error.response)
          setPending(false)
        }
      })
      //console.log('you are in else')
    }
  }
  return (
    <>
      <SignUpAvatar />
      <h2 style={{ marginBottom: "30px" }}>Sign Up</h2>
      <Box component="form" noValidate sx={{ mt: 3 }} className={classes.box} onSubmit={(e)=>{handleSubmit(e)}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              variant="filled"
              onChange={(e)=>{setFirstName(e.target.value)}}
              error={firstNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              variant="filled"
              onChange={(e)=>{setLastName(e.target.value)}}
              error={lastNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              variant="filled"
              onChange={(e)=>{setEmail(e.target.value)}}
              error={emailError}
            />
          </Grid>
          <Grid item xs={12}>
          <LightTooltip
        title="Passwords should be atleast 10 characters long and include 1 uppercase letter, 1 number and 1 special character. Passwords are case sensitive."
        placement="right"
      arrow>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="filled"
              onChange={(e)=>{setPassword(e.target.value)}}
              error={passwordError}
            />
        </LightTooltip>
          </Grid>
          <Grid item xs={12}>
          </Grid>
        </Grid>
        {
          pending?
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled
          sx={{ mt: 3, mb: 2 }}
        >
          Please Wait....
        </Button>
          :
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
        }
        
        <Grid container justifyContent="flex-end">
          <Grid item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default SignUp;
