import { useState, useEffect } from 'react';
import SourceBookSelect from './SourceBookSelect';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
//import { API } from './stores/api';
// import { API } from '../../../Services/ApiUrl'

import Box from '@material-ui/core/Box';
import SoureList from './SourceList';
import UploadButton from '../UploadButton';
import axios from 'axios';
import { base_url } from '../../../Services/ApiUrl';



    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          padding: theme.spacing(0.5),
        },
        chip: {
          margin: theme.spacing(0.5),
        },
        button: {
          marginLeft: theme.spacing(2),
        },
      }));
      
      const BibleSource = (props) => {   
      const {bibleVersion,bibleBooks,selectedFiles}=props;   
      const [bookList, setBookList] = useState([]);
      const [projectBooks, setProjectBooks] = useState(props.projectBooks[0]);
      const [sourceBooks, setSourceBooks] = useState();
      const [source, setSource] = useState(null);
      //const [selectedFiles1,setSelectedFiles1]=useState('');

      const classes = useStyles();
      useEffect(()=>{
        if(source!==null&& bookList.length!==0){
            bibleBooks(bookList)
 
          }

      },[source,bookList]);

      useEffect(()=>{
        if(source===null&& bookList.length!==0)
        {setBookList([])}

      },[source]);


      
      useEffect(() => {
       // console.log(source)
        if (source !== null && source?.value!=="Upload") {
          //console.log(props.projectBooks);  
          axios.get(`${base_url}bibles/${source?.value}/books`)
            .then(function (response) {
              setSourceBooks(response.data);
            })
            .catch((error) => {console.log(error)});

        }
      

      }, [source]);

      useEffect( ()=>{ 
        if (((source!==null) && (bookList.length!==0))||((source!==null) && (source?.value==="Upload"))){
        
          (bibleVersion(source.value))
      }
       else {(bibleVersion(null));
        //bookList([]);
        } 


    }, [source,bookList]);
    return ( 
        <> 
        <Box display='flex'>
        <Box mr={1}>
          <SoureList
            onChange={setSource}
            width={300}
            value={source}
            componentName={'Select Source'}
            sourceLangCode={props.sourceLangCode}
          //selectedFiles={SelectedFiles}
          />
        </Box>
       
        {source!==null? (source?.value!=="Upload" && sourceBooks ? (
          <>
            <SourceBookSelect
              onChange={setBookList}
              buttonText='SELECT BOOKS'
              sourceBooks={sourceBooks}
              projectBooks={projectBooks}
              bookList1={bookList}
            />
            {/* {console.log(bookList)} */}
            <Button
              className={classes.button}
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => setBookList([])}
            >
              Clear
            </Button>
          </>
        ): (source?.value==="Upload" ?
          <>
           <UploadButton onChange={selectedFiles}/>
           
          </>:<></>
        )):(<></>)}
      </Box>
      {bookList.length !== 0 && (
        <Paper component='ul' className={classes.root}>
          {bookList.map((data) => {
            return (
              <li key={data}>
                <Chip label={data} className={classes.chip} />
              </li>
            );
          })}
        </Paper>
      )}
{/* {console.log(selectedFiles)}; */}

           {/* { bibleBooks(bookList)} */}

      {/* { (((source!==null) && (bookList.length!==0))||((source!==null) && (source?.value==="Upload")))? (bibleVersion(source.value)):(bibleVersion(""))}  */}
      {/* { ((source!==null) && (source?.value==="Upload"))? (bibleVersion(source.value)):(bibleVersion(''))}  */}

{/* {(source!==null)?((bookList.length!==0)?(bibleVersion(source.value)):(bibleVersion(source.value))):(bibleVersion(null))} */}
      {/* {console.log(bibleVersion)} */}
      </>
    );
}
 
export default BibleSource;