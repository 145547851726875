import { useState, useEffect } from "react";
import Menu from "./Menu";
import Box from "@material-ui/core/Box";

import BibleCommentary from "./reference-resources/BibleCommentary/BibleCommentary";
import OtherBible from "./reference-resources/OtherBible/OtherBible";
import TranslationNotes from "./reference-resources/TranslationNotes";
import TranslationWords from "./reference-resources/TranslationWords";

import { BIBLES_ABBRV_INDEX } from "./BCVDropdownComponents/BooksOfTheBible";
import { base_url } from "../../Services/ApiUrl";
const ReferenceSection = (props) => {
  const bookid = props.bookid;
  const chapter = props.chapter;
  const verse = props.verse;
  const bookname = props.bookname;

  useEffect(() => {}, [chapter]);
  const [signVideoStatus, setSignVideoStatus] = useState(true);
  const [otherBibleStatus, setOtherBibleStatus] = useState(false);
  const [translationWordStatus, setTranslationWordStatus] = useState(false);
  const [translationNotesStatus, setTranslationNotesStatus] = useState(false);
  const [commentaryStatus, setCommentaryStatus] = useState(false);

  const signVideoClick = () => {
    setSignVideoStatus(!signVideoStatus);
  };
  const otherBibleClick = () => {
    setOtherBibleStatus(!otherBibleStatus);
  };
  const translationWordClick = () => {
    setTranslationWordStatus(!translationWordStatus);
  };

  const translationNoteClick = () => {
    setTranslationNotesStatus(!translationNotesStatus);
  };

  const commentaryClick = () => {
    setCommentaryStatus(!commentaryStatus);
  };

  const menuItems = [
    {
      label: "Sign Video",
      onClick: signVideoClick,
      status: signVideoStatus,
    },
    {
      label: "Other Bible",
      onClick: otherBibleClick,
      status: otherBibleStatus,
    },
    {
      label: "Bible Commentary",
      onClick: commentaryClick,
      status: commentaryStatus,
    },
    {
      label: "Translation Word",
      onClick: translationWordClick,
      status: translationWordStatus,
    },
    {
      label: "Translation Notes",
      onClick: translationNoteClick,
      status: translationNotesStatus,
    },
  ];

  return (
    <>
      <div className="View-menu" style={{ display: "flex" }}>
        <div
          style={{
            width: "auto",
            border: "2px solid #5352ed",
            background: "#f1f2f6",
          }}
        >
          <Menu buttonLabel="View Resources" menuItems={menuItems} />
        </div>
      </div>
      <div
        className="flex-container"
        style={{ display: "flex", flexWrap: "wrap", marginBottom: "10px" }}
      >
        {signVideoStatus && (
          <Box
            border={1}
            p={2}
            m={0.5}
            style={{ height: "250px", width: "395px" }}
          >
            Sign Video
            <video width="100%" height="90%" controls>
              <source
                src={`${base_url}media/gitlab/stream?access_token=${localStorage.getItem(
                  "token"
                )}&repo=kavitha.raju%2Fsample-sign-bible-repo&tag=main&file_path=${
                  BIBLES_ABBRV_INDEX[bookid]
                }.${bookid.toUpperCase()}%2FCh_${chapter.padStart(3, "0")}.mp4`}
                type="video/mp4"
              />
            </video>
          </Box>
        )}
        {/* other bible section */}
        {otherBibleStatus && (
          <Box
            border={1}
            p={2}
            m={0.5}
            style={{
              width: "395px",
              height: "250px",
              background: "#2C3A47",
              color: "#F8EFBA",
            }}
          >
            <OtherBible
              bookid={bookid}
              chapter={chapter}
              verse={verse}
              toVerse={props.toVerse}
              bookname={bookname}
            />
          </Box>
        )}
        {/* Other Bible section ends here */}
        {/* Commentary section */}
        {commentaryStatus && (
          <Box
            border={1}
            p={2}
            m={0.5}
            style={{
              width: "395px",
              height: "250px",
              background: "#2C3A47",
              color: "#F8EFBA",
            }}
          >
            <BibleCommentary bookid={bookid} chapter={chapter} verse={verse} />
          </Box>
        )}
        {/* Commentary section ends here */}
        {/* Translation word section */}
        {translationWordStatus && (
          <Box
            border={1}
            p={2}
            m={0.5}
            style={{
              width: "46.5%",
              minWidth: "395px",
              height: "250px",
              background: "#2C3A47",
              color: "#F8EFBA",
            }}
          >
            Translation Word
            <TranslationWords
              bookId={bookid}
              chapter={chapter}
              verse={verse}
              toVerse={props.toVerse}
            />
          </Box>
        )}
        {/* Translation word section ends here */}
        {/* Translation Notes section */}
        {translationNotesStatus && (
          <Box
            border={1}
            p={2}
            m={0.5}
            style={{
              width: "46.5%",
              height: "250px",
              minWidth: "395px",
              background: "#2C3A47",
              color: "#F8EFBA",
            }}
          >
            Translation Notes
            <TranslationNotes
              bookId={bookid}
              chapter={chapter}
              verse={verse}
              toVerse={props.toVerse}
            />
          </Box>
        )}
        {/* Translatoin notes section ends here */}
      </div>
    </>
  );
};

export default ReferenceSection;
