import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
 import ReferenceSection from './ReferenceSection';
import AlignmentEditor from '../Alignment-Editor/AlignmentEditor';
import LoadingPage from '../LoadingAnimation/LoadingPage';
import  {useState, useEffect} from 'react';
import useBibleReference from '../Tokenization/BCVDropdownComponents/useBibleReference';
import BibleReference from '../Tokenization/BCVDropdownComponents/BibleReference';
import { useParams,useLocation, useHistory } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `10px 0 0 ${theme.spacing(2)}px`,
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
}));

const TokenizationPage = () => {
  const {login} = useContext(AuthContext);
  const history =useHistory();
  const stateProjectId=useLocation().state.projectId;
  const stateProjectName=useLocation().state.projectName.toUpperCase();
  const stateSourceLang=useLocation().state.projectSourceLang;
  const stateTargetLang=useLocation().state.projecTargetLang;
  const supportedBook=useLocation().state.supportedBooks;
  const stateSourceCode = useLocation().state.projectSourceCode;
  const stateTargetCode = useLocation().state.projectTargetCode;
  const [isLoading,setIsLoading] = useState(true)
    const classes = useStyles();
    //BCV dropdown related code
    // const supportedBooks = null; // if empty array or null then all books available
    const supportedBooks = supportedBook; // if non-empty array then only these books are shown
    const initialBook = "";
    const initialChapter = "";
    const initialVerse = "";
    const initialToVerse=""
    console.log("targetCode=>",useLocation())
    // select colors
     //const grey = ""; // a shade of grey
    //const blue = "#576574"; // a shade of blue
     const white = "#FFFFFF";
     const black = "#000000";
    // const style = {}; // use defaults
    const style = { color: black, background: white }; // set forground and background colors
   
    function onChange(bookId, chapter, verse, toVerse) {
        console.log(`\n### Reference changed to ${bookId} - ${chapter}:${verse}-${toVerse}\n\n`);
      }
    
      
      const initial =
        {
          initialBook,
          initialChapter,
          initialVerse,
          initialToVerse,
          onChange
        };
      
      const {state, actions} = useBibleReference(initial);
      console.log("check",useBibleReference(initial))
      console.log("reference state",state)
      
      useEffect(() => {
        actions.applyBooksFilter(supportedBooks);
        console.log(supportedBooks)
        window.scrollTo(0, 0);

      }, []); // just apply the first time in this demo
//ends here
    return (
      <>{login ?
      <List className={classes.root}>
      {
        isLoading?
        <LoadingPage />
        :
        null
      }
        {/* <div  className="bible-pagination" style={{display:'flex'}}>
            <Pagination />
        </div> */}

        {/* including  pagination code inside the tokenization page.
        begins-
        */}

<div>
  <div style={{ display: "flex",
            alignItems: "center",
            marginTop: "-20px",
            height: "70px",
            width: "100%",
            justifyContent: "center",
            backgroundImage: "",}}>
  <div style={{border:'.5px solid #40739e',width:'200px',marginBottom:'10px',color:'#273c75'}}>  <h3>{stateProjectName}</h3> </div>
  </div>
  {supportedBook.length > 0 ?
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "-20px",
            height: "70px",
            width: "100%",
            justifyContent: "left",
            backgroundImage: "",
          }}
        >
          <BibleReference status={state} actions={actions} style={style} />
        </div>
        :
        (<div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "-20px",
          height: "70px",
          width: "100%",
          justifyContent: "left",
          backgroundImage: "",
          
        }}
      > 
      <div style={{disabled:true,opacity: 0.5,pointerEvents: 'none',}}>
        <BibleReference status={state} actions={actions} style={style} />
       </div>
     <span style={{color:'#ff3838',opacity:2}}>* Please add book to project</span>
      </div>
        )}
   <Divider component="li" />
        <li>
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            <b>Resources</b>
          </Typography>
        </li>
        <div className="Resources-div" display='flex' style={{minHeight:'10px',justifyContent:'flex-start'}} 
        >
        <ReferenceSection bookid={state.bookId} chapter={state.chapter} verse={state.verse} bookname={state.bookName} toVerse={state.toVerse}/>
        </div>
        <Divider component="li"  />
        <li>
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            <b>Alignment Editor</b>
          </Typography>
          <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',gap:'20px',alignItems:'center'}}>
                                    <div style={{margin:0,padding:0,display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}}><div style={{width:'30px',height:'15px',borderRadius:'5px',backgroundColor:'#c66b3d'}}></div>No Translation</div>
                                    <div style={{margin:0,padding:0,display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}}><div style={{width:'30px',height:'15px',borderRadius:'5px',backgroundColor:'#c4a35a'}}></div>Suggested Translation</div>
                                    <div style={{margin:0,padding:0,display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}}><div style={{width:'30px',height:'15px',borderRadius:'5px',backgroundColor:'#1e847f'}}></div>Confirmed Translation</div>
                                    <div style={{margin:0,padding:0,display:'flex',justifyContent:'center',alignItems:'center',gap:'5px'}}><div style={{width:'30px',height:'15px',borderRadius:'5px',backgroundColor:'#2649fc'}}></div>Selected Token</div>
                            </div>
        </li> 
       
         <div className="sign-div" style={{minHeight:'200px',marginTop:'20px'}}>
         <AlignmentEditor bookid={
                                state.bookId
                            }
                            chapter={
                                state.chapter
                            }
                            verse={
                                state.verse
                            }
                            toVerse={
                                state.toVerse
                            }
                            setloading={setIsLoading}
                            projectId={stateProjectId}
                            sourceLanguage={stateSourceLang}
                            sourceCode={stateSourceCode}
                            targetCode={stateTargetCode}
                            sourceLang={stateSourceLang}
                            />
        
        </div>

       <Divider component="li" />
        <div className="source-div" style={{height:'150px'}}>
        </div>
        </div>
      </List>
      :<>
      {history.push("/")}
      {alert("Access denied,Please Login")}
      </>}
      </>
    );
  }
 
export default TokenizationPage;
