import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CardMedia from "@material-ui/core/CardMedia";

import { TextField, Button, Grid, Box } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import LoadingPage from "../LoadingAnimation/LoadingPage";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useRouteMatch } from "react-router-dom";
// import UploadUsfm from "./UploadUsfm";
//import { grey } from "@material-ui/core/colors";
// import UploadCSV from "./UploadCSV";
import UploadUsfmCsvFile from "./UploadUsfmCsvFile";
import { base_url } from "../../Services/ApiUrl";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
// import SourceBookSelect from "./SourceBookSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 380,
    margin: "10px 10px",
    "&:hover": {
      boxShadow: "3px 3px 10px 5px #ccc",
    },
  },
  media: {
    height: 140,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#FFF",
    border: "2px solid #FFF",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  listItem: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const ViewProject = () => {
  const{login,setLogin}=useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState();
  const [progress, setProgress] = useState({});
  const [progressPending, setProgressPending] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addBookOpen, setAddBookOpen] = React.useState(false);
  const [userIdError, setUserIdError] = useState(false);
  const [projectId, setProjectId] = useState();
  const [projectName, setProjectName] = useState();
  const [projectBooks, setProjectBooks] = useState([]);
  const [addUserProgress, setAddUserProgress] = useState(false);
  const [addBookProgress, setAddBookProgress] = useState(false);
  const [projectSourceLangCode, setProjectSourceLanguageCode] = useState();
  
  const [selectMenu, setSelectMenu] = useState(false);
  const [userName, setUserName] = useState("");
  const [userList, setUserList] = useState([]);
  const [userrole, setUserRole] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if(login){
    //const token = "abcded";
    const token = localStorage.getItem("token");
    let url = `${base_url}autographa/projects`
    axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          app: "Autographa",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((item) => {
        setData(item.data);
        if (item.data.length === 0) {
          alert("No Projects Available");
          // history.push("/");
        }
      })
      .catch((error) => {if(error.response.status===401)
        {
          history.push("/");

          localStorage.removeItem('token')
          localStorage.removeItem('login')
          localStorage.removeItem('userId')
          setLogin(false);
          alert("Session has Expired. Please Log in again")

        }
        else{
          alert("Error Occurred, Please try again after some time")
        }

      });}
  }, []);
  //getting user role
  useEffect(() => {
    if(login){
    const token = localStorage.getItem("token");
    //const token = "abcded";
    
    const userId = localStorage.getItem("userId");
    let url = `${base_url}user/${userId}`
    axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          app: "Autographa",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((item) => {
        setUserRole(item["data"].traits["userrole"]);
    //    console.log(item["data"].traits["userrole"]);
        if (item.data.length === 0) {
          alert("Permission Denied");
          history.push("/");
        }
      })
      .catch((error) => {
        if(error.response.status===401)
        {
          history.push("/");

          localStorage.removeItem('token')
          localStorage.removeItem('login')
          localStorage.removeItem('userId')
          setLogin(false);
          alert("Session has expired. Please log in again.")

        }
        else{
          alert("Error Occurred, Please try again after some time")
        }

      });}
  }, []);

  const handleOpen = (p) => {
    // setOpen(true);
    setProjectId(p);
    setAddUserProgress(true);
    const token = localStorage.getItem("token");
    let url = `${base_url}users`
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((item) => {
        setUserList(item.data);
        setOpen(true);
        setAddUserProgress(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alert("Permission Denied");
          setAddUserProgress(false);
        }else if(error.response.status===401)
        {
          setAddUserProgress(false);

          history.push("/");

          localStorage.removeItem('token')
          localStorage.removeItem('login')
          localStorage.removeItem('userId')
          setLogin(false);
          alert("Session was dle for too long, access token expired")

        }

         else {
          setAddUserProgress(false);
          alert("Error Caused, Please try again1", error.response.status);
        }
      });
  };
  const handleOpenAddBook = (p, pn,b,lc) => {
    // setOpen(true);
    setProjectId(p);
    setProjectName(pn);
    setProjectBooks([b]);
    setProjectSourceLanguageCode(lc);
    setAddBookProgress(true);
    //const token = localStorage.getItem("token");
    setAddBookOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddBookClose = () => {   
    setAddBookOpen(false);
  };

  const handleSelectMenuOpen = () => {
    setSelectMenu(true);
  };
  const handleSelectMenuClose = () => {
    setSelectMenu(false);
  };

  const handleMenuChange = (e) => {
    setUserName(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName === "") {
      alert("please select a user");
    } else {
      const token = localStorage.getItem("token");
      const url = `${base_url}autographa/project/user?project_id=${projectId}&user_id=${userName}`;
      let statusCode;
      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          app: "Autographa",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          statusCode = res.status;
          return res.json();
        })
        .then((data) => {
          if (statusCode === 403) {
            alert("Permission denied");
          } else if (statusCode === 409) {
            alert("User already exist in project");
          } else if (statusCode === 404) {
            alert("User doesnt exist");
          } else if (statusCode === 201) {
            alert("User added succesfully");
            handleClose();
          } else {
            alert("error occurred please try again");
          }
        })
        .catch((error) => {});
    }
  };
  const show = (index, id) => {
    for (let i = 0; i < data.length; i++) {
      setProgressPending(true);
      if (i === index) {
        if (
          document.getElementById(i).style.display === "block" ||
          document.getElementById(i).style.display === ""
        ) {
          document.getElementById(i).style.display = "none";
        } else {
          document.getElementById(i).style.display = "block";
        }
      } else {
        document.getElementById(i).style.display = "none";
      }
    }
    const token = localStorage.getItem("token");
    let url = `${base_url}autographa/project/progress?project_id=${id}`
    axios
      .get(
        url,
        {
          headers: {
            "content-type": "application/json",
            app: "Autographa",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((item) => {
        setProgress(item.data);
        setProgressPending(false);
      })
      .catch((error) => {});
  };
  return (
    <>{login
    ?
    <>
      {addUserProgress ? <LoadingPage /> : null}
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: 55,
            justifyContent: "flex-start",
          }}
        >
          {/* {console.log(typeof(userrole))} */}

          {userrole.includes("SuperAdmin", 0) ||
          userrole.includes("AgAdmin", 0) ||
          userrole.includes("AgUser", 0) ? (<>
            <Card className={classes.root} >
              <Link
                to="/create-project"
                style={{ textDecoration: "none", color: "black" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Create Project"
                    height="160"
                    image="images/newfolder2.png"
                    title="Create Project"
                    style ={{
                      height: '80%',
                      width: '40%',
                      margin:'auto'
                    //  objectFit: 'cover'
                    }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontFamily: "Helvetica Neue", color: "#0a3d62" }}
                    >
                      Create Project
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
            <Card className={classes.root} >
              <Link
                to="/Dictionary"
                style={{ textDecoration: "none", color: "black" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Dictionary Management"
                    height="160"
                    image="images/dict1.png"
                    title="Dictionary Management"
                  style ={{
                    height: '80%',
                    width: '40%',
                    margin:'auto'
                  //  objectFit: 'cover'
                  }}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ fontFamily: "Helvetica Neue", color: "#0a3d62" }}
                    >
                      Dictionary Management
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
            
            </>
          ) : (
            <></>
          )}

          {data
            ? data.map((item, index) => {
              console.log(item)
                return (
                  <Card
                    className={classes.root}
                    key={index}
                    style={{ minHeight: "210px" }}
                  >
                    <Link
                      style={{ textDecoration: "none", color: "#40739e" }}
                      to={{
                        pathname: "/token/Project-Data",
                        state: {
                          projectId: item.projectId,
                          projectName: item.projectName,
                          projectSourceLang: item.sourceLanguage.language,
                          projecTargetLang: item.targetLanguage.language,
                          supportedBooks: item.metaData.books,
                          projectSourceCode:item.sourceLanguage.code,
                          projectTargetCode:item.targetLanguage.code
                        },
                      }}
                    >
                      <CardActionArea >
                        {/* {console.log(item.projectName)}
                    
                    {console.log(item.metaData.books)} */}
                        <CardContent >
                          <Typography gutterBottom variant="h5" component="h2">
                            {item.projectName}
                            {/* {console.log(item.projectName, item.users)} */}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            <h3>
                              Source Language : {item.sourceLanguage.language}
                            </h3>
                            <h3>
                              Target Language : {item.targetLanguage.language}
                            </h3>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Link>
                    {
                      // userrole.includes("SuperAdmin", 0) ||
                      // userrole.includes("AgAdmin", 0) ||
                      // userrole.includes("AgUser", 0) ? (

                      item.users.find(
                        (e) =>
                          e.userId === userId && e.userRole !== "projectMember"
                      ) ||
                      userrole.includes("SuperAdmin", 0) ||
                      userrole.includes("AgAdmin", 0) ? (
                        <CardActions style={{marginLeft:10}}>
                          <div style={{margin:'auto'}}>
                          <Button
                            size="small"
                            color="primary"
                            type="button"
                            onClick={() => {
                              handleOpen(item.projectId);
                            }}
                          >
                            Add User
                          </Button>
                          <Button size="small" color="primary" name="box">
                            <span
                              onClick={() => {
                                show(index, item.projectId);
                              }}
                            >
                              View Progress
                            </span>
                          </Button>
                          {/* <SourceBookSelect /> */}
                          <Button size="small" color="primary" name="box">
                            <span
                              onClick={() => {
                                // show(index, item.projectId);
                                handleOpenAddBook(
                                  item.projectId,
                                  item.projectName,
                                  item.metaData.books,
                                  item.sourceLanguage.code
                                );
                              }}
                            >
                              Add Books
                            </span>
                          </Button>
                          </div>
                        </CardActions>
                      ) : (
                        console.log()
                      )
                    }
                    <div style={{ display: "none" }} id={index}>
                      {progressPending ? (
                        <LoadingAnimation />
                      ) : progress ? (
                        <div>
                          <h3 style={{ margin: "0px" }}>
                            Confirmed:{" "}
                            {parseFloat(progress.confirmed * 100).toFixed(2)} %
                          </h3>
                          <h3 style={{ margin: "0px" }}>
                            Suggestion:{" "}
                            {parseFloat(progress.suggestion * 100).toFixed(2)} %
                          </h3>
                          <h3 style={{ margin: "0px 0px 15px 0px" }}>
                            Untranslated:{" "}
                            {parseFloat(progress.untranslated * 100).toFixed(2)}{" "}
                            %
                          </h3>
                        </div>
                      ) : null}
                    </div>
                  </Card>
                );
              })
            : null}
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <Box
                    component="form"
                    noValidate
                    sx={{ mt: 3 }}
                    className={classes.box}
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <Typography variant="h4" style={{ marginBottom: "30px" }}>
                      Add User
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-controlled-open-select-label">
                        Users
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        onClose={handleSelectMenuClose}
                        onOpen={handleSelectMenuOpen}
                        value={userName}
                        onChange={handleMenuChange}
                        style={{ marginBottom: "20px" }}
                      >
                        {userList.map((item, index) => {
                          return (
                            <MenuItem value={item.userId} key={index}>
                              {item.name.fullname}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Add Project
                    </Button>
                  </Box>
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-AddBook"
              aria-describedby="transition-modal-AddBook-description"
              className={classes.modal}
              open={addBookOpen}
              onClose={handleAddBookClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <div className={classes.paper}>
                <UploadUsfmCsvFile
                  projectId={projectId}
                  projectName={projectName}
                  projectBooks={projectBooks}
                  projectSourceLangCode={projectSourceLangCode}
                />

              </div>
            </Modal>
          </div>
        </div>
      </div>

      {/* Add User */}
    </>
  :<>
      {history.push("/")}
      {alert("Access denied,Please Login")}
  </>}
  </>
  );
};

export default ViewProject;
