import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'
import { useState } from 'react';
import ReleaseNotes from './ReleaseNotes';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        marginLeft: 10,
        color:"#FFF",
        cursor:'pointer'
    },
    container: {
        backgroundColor: "#353b48",
        height: 50,
        display: 'flex', 
        alignItems: "center", 
        justifyContent: 'space-between', 
        width: "100%", 
        position: 'relative', 
        bottom: 0
    }
}));

const Footer = () => {
    const [showModal,setShowModal] = useState(false)
    const classes = useStyles();
    return (
        <>
        <ReleaseNotes
        showModal={showModal}
        setShowModal={setShowModal}
        />
        <div className={classes.container}>
            {/* <Typography variant="h6" className={classes.title} align="left">
                <Link to="/" style={{ textDecoration: "none", color: "#FFF" }}>
                    Machine Assisted Sign Translation
                </Link>
            </Typography> */}
            <Typography variant="p" className={classes.title} align="left" onClick={()=>{setShowModal(!showModal)}}>
                    Release Notes
            </Typography>
        </div>
        </>
    );
}

export default Footer;