import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { base_url } from "../../../Services/ApiUrl";
import { Box } from "@material-ui/core";
import axios from "axios";

export default function SourceList(props) {
  const { width, value, onChange } = props;
  const newOption = { label: "Upload Source File", value: "Upload" };
  const [sourceLanguages, setSourceLanguages] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${base_url}sources?content_type=bible&language_code=${props.sourceLangCode}&access_tag=derivable&latest_revision=False&skip=0&limit=100`
      )
      .then(function (response) {
        console.log(response.data);
        const source = response.data.map((item) => {
          const label =
            item.language.language +
            " - " +
            item.version.versionAbbreviation +
            " - " +
            item.version.revision;
          return { value: item.sourceName, label: label };
        });
        source.push(newOption);
        setSourceLanguages(source);
      
      });
  }, []);
  return (
    <Box style={{ width: width || 300 }}>
      <Select
        onChange={(option) => onChange(option)}
        options={sourceLanguages}
        maxMenuHeight={130}
        placeholder={sourceLanguages ? props.componentName : "Loading"}
        isSearchable
        isClearable
        value={value}
        isLoading={!sourceLanguages}
        isDisabled={!sourceLanguages}
      />
    </Box>
  );
}

SourceList.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  width: PropTypes.number,
  componentName: PropTypes.string,
};
