import { useContext, useEffect,useState } from "react";
import axios from "axios";
import Parser from 'html-react-parser';
import { base_url } from "../../../../Services/ApiUrl";
import SelectCommentaryVer from "./SelectCommentaryVer";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthContext";

const BibleCommentary = (props) => {
  const history=useHistory();
  const {setLogin} =useContext(AuthContext);
    const bookid = props.bookid;
  const chapter = parseInt(props.chapter);
  const verse = parseInt(props.verse);
  const [loading, setLoading] = useState(false);

  const [comment,setComment]=useState('');
  const [commentary,setCommentary]=useState(null);
useEffect(()=>{


  if(commentary?.value!==undefined)
{
setLoading(true);
  (axios.get(`${base_url}commentaries/${commentary?.value}?book_code=${bookid}&chapter=${chapter}&verse=${verse}`,{
    headers:{
      'content-type': 'application/json',
       app: "Autographa",
       Authorization: `Bearer ${localStorage.getItem('token') }`,}
  }).then((item)=>{

   console.log("Commentary returned",item.data)
   if(item.data.length!==0){
    setComment(item['data'][0].commentary);
    setLoading(false);}
    else{
    setComment("Sorry, Commentary Not Available");
setLoading(false);
   }
  }).catch((error)=>{
    if(error.response.status===401)
    {
      history.push("/");
      localStorage.removeItem('token')
      localStorage.removeItem('login')
      localStorage.removeItem('userId')
      setLogin(false);
      alert("Session has Expired. Please Log in again")

    }

    console.log(error);
  }))}
},[commentary,bookid,chapter,verse])
    return ( 
<>
<div style={{display:'flex',justifyContent:'space-between'}}>
          <div style={{width:'250px'  ,justifyContent:'center'}}>
          <p>Commentary</p>
          </div>
          <div style={{color:'black',margin:'auto'}}>
  
  <SelectCommentaryVer
   onChange={setCommentary}
   width={200}
   height={250}
   value={commentary}
   componentName={'Select Commentary'}
   />
   
  </div>      
        </div>
      

    <div style={{overflowY:'scroll',height:'180px',marginTop:'16px',background:'#f7f1e3',color:'#40407a'}}>
    <div style={{marginTop:20}}>
    {loading?<p style={{textAlign:'center'}}>Loading...</p>:(comment.length!==0 ?(Parser(comment)):(<><div><p>{comment}</p></div></>))}
    </div>

    
    </div>
</>
         
  
        
        
     );
}
 
export default BibleCommentary;