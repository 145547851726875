import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import TextField from "@material-ui/core/TextField";
import useAxios from "./useAxios";
import Button from "@material-ui/core/Button";

import {
  DataGrid,
  GridFooter,
  GridFooterContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

import RemoveDialog from "./RemoveDialogue";
import EditSign from "./EditSign";
import { base_url } from "../../Services/ApiUrl";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useHistory } from "react-router-dom";
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </div>

      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function QuickFilter() {
  const {login,setLogin} = useContext(AuthContext);
  const [showRowCountFlag, setShowRowCountFlag] = useState(false);
  const [rowCount, setRowCount] = useState();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const history =useHistory();
  // const [state, setState] = React.useState({
  //   checkedEmptyVideoUrl: true,
  //   checkedSeeMore: false,
  // });

  const handlePlayButton = (params, divid) => {
    document.getElementById(`${divid}`).innerHTML = `<div>
<video width='280' height='200' controls muted autoplay>
<source src=${params} type="video/mp4" />

</video></div>`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowRowCountFlag(true);
    setSearchedText(searchText);
    fetch(
      `${base_url}dictionaries/ins_IRV_1_dictionary?search_word=${searchText}&exact_match=false&word_list_only=false&active=true&skip=0&limit=100`
    )
      .then((res) => res.json())
      .then((response) => {
        if (response !== null) {
          setRowCount(response.length);
          const dictionary = response.map((c, i) => {
            const Dictdata = {};
            Dictdata.id = c.word;
            Dictdata.video = c.details.videoUrl;
            Dictdata.word = c.word;
            Dictdata.videoUrl = c.details.videoUrl;
            Dictdata.description = c.details.description;
            Dictdata.relatedSigns = c.details.relatedSigns;
            Dictdata.remarks = c.details.remarks;
            Dictdata.synonyms = c.details.synonyms;
            Dictdata.active = c.active;

            return Dictdata;
          });
          setData(dictionary);
        }
      });
  };

  const { response, loading, error } = useAxios({
    method: "get",

    url: "dictionaries/ins_IRV_1_dictionary?exact_match=false&word_list_only=false&active=true&skip=0&limit=100",
  });

  useEffect(() => {
    if (response !== null) {
      const dictionary = response.map((c, i) => {
        const Dictdata = {};
        Dictdata.id = c.word;
        Dictdata.video = c.details.videoUrl;
        Dictdata.word = c.word;
        //Dictdata.videoUrl = c.details.videoUrl;
        Dictdata.description = c.details.description;
        Dictdata.relatedSigns = c.details.relatedSigns;
        Dictdata.remarks = c.details.remarks;
        Dictdata.synonyms = c.details.synonyms;
        Dictdata.active = c.active;

        return Dictdata;
      });
      setData(dictionary);
      setData1(dictionary);
      setRowCount(0);
      setShowRowCountFlag(false);
      setSeeMoreFlag(false);
    }
  }, [response]);

  function CustomFooter() {
    return (
      <GridFooterContainer>
        {seeMoreFlag === true && (
          <>
            <div style={{ marginLeft: "10%", position: "absolute" }}>
              <Button
                style={{ color: "#2980b9" }}
                startIcon={<SearchIcon />}
                onClick={handleSubmit}
              >
                <b>See More</b>
              </Button>
            </div>
            {showRowCountFlag && (
              <div style={{ marginLeft: "30%", position: "absolute" }}>
                <p>
                  [<b>see more result</b> :{" "}
                  {rowCount > 1
                    ? `${rowCount} rows found`
                    : `${rowCount} - row found`}{" "}
                  ]
                </p>
              </div>
            )}
          </>
        )}
        <div style={{ marginLeft: "80%", position: "absolute" }}>
          {" "}
          <GridFooter />
        </div>
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: "word",
      headerName: "Sign",
      flex: 1,
      maxWidth: 130,
    },
    {
      field: "video",
      headerName: "Video",
      flex: 1,
      minWidth: 300,
      minHeight: 210,
      filterable: false,
      renderCell: (params) => {
        if (params.value !== "") {
          return (
            <div id={params.row.word} style={{ margin: "auto" }}>
              <Button
                onClick={(e) => handlePlayButton(params.value, params.row.word)}
                variant="outlined"
                style={{ color: "#26de81" }}
              >
                Play <PlayCircleOutlineIcon />
              </Button>
            </div>
          );
        } else {
          return <div style={{ margin: "auto" }}>Sorry No Video Available</div>;
        }
      },

      sortable: false,
    },

    {
      field: "synonyms",
      headerName: "Synonyms",
      flex: 1,
    },
    {
      field: "relatedSigns",
      headerName: "Related Signs",
      flex: 1,
      renderCell: (params) => {
        if (params.value !== "") {
          return params.value.map((relatedword) => relatedword.word).join(", ");
        }
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
    },

    {
      field: "action",
      //renderCell: (data) => <Metadata data={data} />,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <EditSign
              selectedWord={params.row.word}
              synonyms={params.row.synonyms}
              description={params.row.description}
              videourl={params.row.video}
              relatedSigns={params.row.relatedSigns}
              remarks={params.row.remarks}
            />
            <RemoveDialog
              selectedWord={params.row.word}
              synonyms={params.row.synonyms}
              description={params.row.description}
              videourl={params.row.video}
              relatedSigns={params.row.relatedSigns}
              remarks={params.row.remarks}
            />
          </div>
        );
      },
      filterable: false,
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      align: "center",
      sortable: false,
    },
  ];

  const [searchText, setSearchText] = React.useState("");
  const [searchedText, setSearchedText] = React.useState("");
  const [rows, setRows] = React.useState(data);
  const [seeMoreFlag, setSeeMoreFlag] = React.useState(false);
  const [dataGridHeight, setDataGridHeight] = React.useState("");

  const closeSearch = () => {
    setData(data1);
    if (data1.length === 1) {
      setDataGridHeight("388px");
    } else if (data1.length === 2) {
      setDataGridHeight("609px");
    } else if (data1.length === 3) {
      setDataGridHeight("839px");
    } else if (data1.length === 4) {
      setDataGridHeight("1049px");
    } else {
      setDataGridHeight("1270px");
    }
    setSeeMoreFlag(false);
  };

  const requestSearch = (searchValue) => {
    if (searchValue === "") {
      setSeeMoreFlag(false);
    }
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
    if (filteredRows.length > 0 && searchValue.length > 0) {
      if (searchedText !== searchText) {
        setShowRowCountFlag(false);
      }

      setSeeMoreFlag(true);
      if (filteredRows.length === 1) {
        setDataGridHeight("388px");
      } else if (filteredRows.length === 2) {
        setDataGridHeight("609px");
      } else if (filteredRows.length === 3) {
        setDataGridHeight("839px");
      } else if (filteredRows.length === 4) {
        setDataGridHeight("1049px");
      } else {
        setDataGridHeight("1270px");
      }
    } else if (searchValue.length > 0 && filteredRows.length === 0) {
      setSeeMoreFlag(false);
      fetch(
        `${base_url}dictionaries/ins_IRV_1_dictionary?search_word=${searchValue}&exact_match=false&word_list_only=false&active=true&skip=0&limit=100`
      )
        .then((res) => res.json())
        .then((response) => {
          if (response !== null) {
            const dictionary = response.map((c, i) => {
              const Dictdata = {};
              Dictdata.id = c.word;
              Dictdata.video = c.details.videoUrl;
              Dictdata.word = c.word;
              Dictdata.videoUrl = c.details.videoUrl;
              Dictdata.description = c.details.description;
              Dictdata.relatedSigns = c.details.relatedSigns;
              Dictdata.remarks = c.details.remarks;
              Dictdata.synonyms = c.details.synonyms;
              Dictdata.active = c.active;

              return Dictdata;
            });
            setData(dictionary);
          }
        });
      if (data.length <= 1) {
        setDataGridHeight("388px");
      } else if (data.length === 2) {
        setDataGridHeight("609px");
      } else if (data.length === 3) {
        setDataGridHeight("839px");
      } else if (data.length === 4) {
        setDataGridHeight("1049px");
      } else {
        setDataGridHeight("1270px");
      }
    } else {
      closeSearch();
      setRowCount(0);
      setShowRowCountFlag(false);
    }
  };

  React.useEffect(() => {
    setRows(data);

    if (data.length === 1) {
      setDataGridHeight("388px");
    } else if (data.length === 2) {
      setDataGridHeight("609px");
    } else if (data.length === 3) {
      setDataGridHeight("839px");
    } else if (data.length === 4) {
      setDataGridHeight("1049px");
    } else {
      setDataGridHeight("1270px");
    }
  }, [data]);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          height: `${dataGridHeight}`,
          minHeight: "380px",
          width: "90%",
        }}
      >
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar, Footer: CustomFooter }}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          pageSize={5}
          rowHeight={210}
          loading={loading}
          rowsPerPageOptions={[]}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />{" "}
      </div>
    </div>
  );
}
