//import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import ReactSelect from 'react-select';
// import { API } from '../../store/api';
import { Box,TextField } from '@material-ui/core';

export default function ProjectSelect(props) {
  const { value } = props;
  
  return (
    <Box style={{ width: 'auto' }}>
      
        <TextField
              name="projectName"
              //required
              fullWidth
              label="Project Name"
              id="projectName"
              variant="outlined"
              value={value}
              disabled
              // onChange={(e) => {
              //   setProjectName(e.target.value);
              // }}
              // error={projectNameError}
            />
    </Box>
  );
}
ProjectSelect.propTypes = {
  //onChange: PropTypes.func,
  value: PropTypes.string,
};