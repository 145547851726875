
import React, { createContext } from 'react';
import { useState } from 'react';

export const AuthContext =createContext();

const AuthContextProvider = (props) => {
    const [login,setLogin]=useState(localStorage.getItem("login")?localStorage.getItem("login"):false);

    return (

<AuthContext.Provider value={{login,setLogin}}>
    {props.children}

</AuthContext.Provider>
      );
}
 
export default AuthContextProvider;