import Navbar from './Component/Navbar/Navbar';
import Home from './Component/Home';
import SignUp from './Component/Login/SignUp';
import Login from './Component/Login/Login';
import './App.css';
import CreateProject from "./Component/Project/CreateProject";
import ViewProject from "./Component/Project/ViewProject";
// import AlignmentEditor from './Component/Alignment-Editor/AlignmentEditor';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import TokenizationPage from './Component/Tokenization/TokenizationPage';
import DictionaryManagementPage from './Component/DictionaryManagementPage';
import AuthContextProvider from './Context/AuthContext';
import Footer from './Component/Footer/Footer';

function App() {
  return (
    <div className="App">
    <AuthContextProvider>
      <Router>
      <Navbar />

    <Switch>
    <div style={{margin: "80px 10px"}}>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/home">
     
         <ViewProject />
      </Route>
      <Route  path="/token/:type"
        component={TokenizationPage}
      />
      <Route exact path="/signup">
        <SignUp/>
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/create-project">
        <CreateProject />
      </Route>
      <Route exact path="/Dictionary">
        <DictionaryManagementPage />
      </Route>
      <Route exact path="/view-project">
        <ViewProject />
      </Route>
      {/* <Route exact path="/alignment-editor/:id">
        <AlignmentEditor setlogin={setLogin}/>
      </Route> */}
    </div>
    </Switch>
    <Footer />
    </Router>
    </AuthContextProvider>
    </div>
  );
}

export default App;
