import { useContext, useState } from "react";
import { TextField, makeStyles, Button, Grid, Box } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import { base_url } from "../../Services/ApiUrl";
import { AuthContext } from "../../Context/AuthContext";

const useStyle = makeStyles({
  box: {
    maxWidth: "500px",
    margin: "0px auto",
  },

  gridLeft: {
    paddingLeft: "80px",
    alignItems: "center",
    fontSize: "16px",
  },
  gridRight: {
    alignItems: "center",
    marginBottom: "10px",
  },
});

const CreateProject = () => {
  const history = useHistory();
  const classes = useStyle();
  const {login,setLogin}=useContext(AuthContext);
  const [projectName, setProjectName] = useState("");
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [projectNameError, setProjectNameError] = useState(false);
  const [sourceError, setSourceError] = useState(false);
  const [targetError, setTargetError] = useState(false);
  const [pending, setPending] = useState(false);
  //enable/disable Save button 
  const canBeSubmitted = () => {
    return (
      projectName.length > 0 &&
      sourceLanguage instanceof Object &&
      targetLanguage instanceof Object
    );
  };

  const isEnabled = canBeSubmitted();



  //
  const handleSubmit = (e) => {
    e.preventDefault();
    setProjectNameError(false);
    setSourceError(false);
    setTargetError(false);
    setPending(false);
    if (projectName === "") {
      setProjectNameError(true);
    } else if (sourceLanguage === "") {
      setSourceError(true);
    } else if (targetLanguage === "") {
      setTargetError(true);
    } else {
      setPending(true);
      const token = localStorage.getItem("token");
      console.log(sourceLanguage);
      console.log(targetLanguage);
      axios
        .post(
          `${base_url}autographa/projects`,
          {
            projectName: projectName,
            sourceLanguageCode: sourceLanguage.code,
            targetLanguageCode: targetLanguage.code,
          },
          {
            headers: {
              "content-type": "application/json",
              app: "Autographa",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((item) => {
          alert("Project Created Successfully");
          history.push("/view-project");
          setPending(false);
        })
        .catch((error) => {if(error.response.status===401)
          {
            history.push("/");
            setPending(false);
            localStorage.removeItem('token')
            localStorage.removeItem('login')
            localStorage.removeItem('userId')
            setLogin(false);
            alert("Session has Expired. Please Log in again")
  
          }
          else if (error.response.status === 403) {
            
            alert("Permission Denied");
            setPending(false);
            //history.push("/");
          } else if (error.response.status === 422) {
            alert("Invalid Language id");
            setPending(false);
          } else if (error.response.status === 409) {
            alert("Test already exist");
            setPending(false);
          } else {
            setPending(false);
            alert("Error Caused, Please try again");
          }
        });
    }
  };
  return (
    <>
      {login?<>
      <h1>Create Project</h1>
      <Box
        component="form"
        noValidate
        sx={{ mt: 3 }}
        className={classes.box}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              name="projectName"
              required
              fullWidth
              label="Project Name"
              id="projectName"
              variant="outlined"
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
              error={projectNameError}
            />
          </Grid>
          <Grid className={classes.gridLeft} item md={5} sm={12} container>
            <span>Source Language</span>
          </Grid>
          <Grid className={classes.gridRight} item md={7} sm={12} container>
            <LanguageSelect
              onChange={setSourceLanguage}
              //width={212}
              value={sourceLanguage}
            />
          </Grid>

          <Grid className={classes.gridLeft} item md={5} sm={12} container>
            <span>Target Language</span>
          </Grid>
          <Grid item md={7} sm={12} container>
            <LanguageSelect
              onChange={setTargetLanguage}
              // width={212}
              value={targetLanguage}
            />
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
        {pending ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled
            sx={{ mt: 3, mb: 2 }}
          >
            Please Wait....
          </Button>
        ) : (
          <Button
            disabled={!isEnabled}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Project
          </Button>
        )}

        <Grid container justifyContent="flex-end">
          <Grid item></Grid>
        </Grid>
      </Box>
      </>
    
  :<>
  {history.push("/")}
      {alert("Access denied,Please Login")}
  </>}</>
  );
};

export default CreateProject;
 
