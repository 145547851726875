const UploadButton = (props) => {
  return (
    <div>
      <input
        accept=".usfm,.sfm,.csv"
        style={{ marginTop: "10px" }}
        type="file"
        title="CSV(with Book-Chapter-Verse-Text fields), USFM or SFM formats are supported"
        onChange={(e) => props.onChange(e.target.files)}
        multiple
      />
    </div>
  );
};

export default UploadButton;
