import SignUpAvatar from "./SignUpAvatar";
import { TextField,makeStyles,Button,Grid,Box } from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import {Redirect, useHistory} from 'react-router-dom'
import { base_url } from "../../Services/ApiUrl";
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
// import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useStyle = makeStyles({
  box:{
    maxWidth: "500px",
    margin: "0px auto"
  }
});
const useStyles1 = makeStyles((theme) => ({
  
  // margin: {
  //   margin: theme.spacing(1),
  // },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

const Login = (props) => {
  const classes = useStyle();
  const {setLogin}=useContext(AuthContext);
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [emailError,setEmailError] = useState(false)
  const [passwordError,setPasswordError] = useState(false)
  const [pending,setPending] = useState(false)
  const history=useHistory();

  const handleSubmit = (e) =>{
    e.preventDefault();
    // setPending(true)
    setEmailError(false)
    setPasswordError(false)
    if(email==='')
    {
      setEmailError(true)
      setPending(false)
    }
    else if(password==='')
    {
      setPasswordError(true)
      setPending(false)
    }else{ 
        const url = `${base_url}user/login?user_email=${email}&password=${password}`
          axios.get(url,{
        headers:{
          'content-type': 'application/json'
        }
      }).then((item)=>{
      
        localStorage.setItem('login',true);
        localStorage.setItem('token',item.data.token);
        localStorage.setItem('userId',item.data.userId);
        setLogin(true);
        history.push("/home");

      }).catch((error)=>{
        if(error.response.status===401)
        {
          alert("Invalid Credential")
        }
        else{
          alert("Error Occurred, Please try again after some time")
          setPending(false)
        }
      })
    }
  }
  //
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    //console.log(values.password)
  setPassword(encodeURIComponent(event.target.value))
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //
  const classes1 = useStyles1();
  return (
    <div style={{marginBottom:100}}>
      <SignUpAvatar />
      <h2 style={{ marginBottom: "30px" }}>Login</h2>
      <Box component="form" noValidate sx={{ mt: 3 }} className={classes.box} onSubmit={(e)=>{handleSubmit(e)}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              variant="filled"
              onChange={(e)=>{setEmail(e.target.value)}}
              error={emailError}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="filled"
              onChange={(e)=>{setPassword(encodeURIComponent(e.target.value))}}
              error={passwordError}
            /> */}
            <FormControl className={clsx(classes1.margin, classes1.textField)} variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            error={passwordError}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

          </Grid>
          <Grid item xs={12}>
          </Grid>
        </Grid>
        {
          pending?
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled
          sx={{ mt: 3, mb: 2 }}
        >
          Please Wait....
        </Button>
          :
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Login
        </Button>
        }
        
        <Grid container justifyContent="flex-end">
          <Grid item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Login;
