import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import TextField from "@material-ui/core/TextField";
import useAxios from "./useAxios";

import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
//import { useDemoData } from '@mui/x-data-grid-generator';
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

import EditIcon from "@material-ui/icons/Edit";
//import { blue } from "@material-ui/core/colors";
import { FormControlLabel } from "@material-ui/core";
import RemoveDialog from "./RemoveDialogue";
import EditSign from "./EditSign";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useHistory } from "react-router-dom";
function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />

      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function QuickFilter3() {
  const history=useHistory();
  const [data, setData] = useState([]);
  const {login,setLogin} =useContext(AuthContext);
  const { response, loading, error } = useAxios({
    method: "get",
   
    url: "dictionaries/ins_IRV_1_dictionary?exact_match=false&word_list_only=false&details=%7B%22videoUrl%22:%22%22%7D&active=true&skip=0&limit=100",

  });

  useEffect(() => {
    if (response !== null) {
      const dictionary = response.map((c, i) => {
        const Dictdata = {};
        Dictdata.id = c.word;
        Dictdata.video = c.details.videoUrl;
        Dictdata.word = c.word;
        //Dictdata.videoUrl = c.details.videoUrl;
        Dictdata.description = c.details.description;
        Dictdata.relatedSigns = c.details.relatedSigns;
        Dictdata.remarks = c.details.remarks;
        Dictdata.synonyms = c.details.synonyms;
        Dictdata.active = c.active;

     
        return Dictdata;
      });
      console.log(dictionary);
      setData(dictionary);
    }
    
  }, [response]);
  
  const columns = [
    {
      field: "word",
      headerName: "Sign",
      flex: 1,
      maxWidth: 130,
      // headerAlign: "center",
    },
    {
      field: "video",
      headerName: "Video",
      flex: 1,
      minWidth: 300,
      filterable: false,
      renderCell: (params) => {
        if (params.value !== "") {
          return (
            <video width="280" height="200" controls muted>
              <source src={params.value} type="video/mp4" />
            </video>
          );
        } else {
          return <div>Sorry No Video Available</div>;
        }
      },

      //    headerAlign: "center",
      sortable: false,
    },

//     {
//       field: "videoUrl",
//       headerName: "Video Url",
//       // minWidth: 150,
//       // renderCell: (params) => {
//       //   if (params.value !== "") {
//       //     return "." + params.value.slice(81);
//       //   }
//       // },
// hide:true,
//       flex: 1,
//     },

    {
      field: "synonyms",
      headerName: "Synonyms",
      flex: 1,
     },
    {
      field: "relatedSigns",
      headerName: "Related Signs",
      flex: 1,
      renderCell: (params) => {
        if (params.value !== "") {
          return params.value.map((relatedword) => relatedword.word).join(", ");
        }
      },

    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
    },

    {
      field: "action",
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "space-around" }}
          >

            <EditSign
              selectedWord={params.row.word}
              relatedSigns={params.row.relatedSigns}
              
              synonyms={params.row.synonyms}
              description={params.row.description}
              videourl={params.row.video}
              remarks={params.row.remarks}
              
            />
            <RemoveDialog selectedWord={params.row.word} 
            
            relatedSigns={params.row.relatedSigns}
              
              synonyms={params.row.synonyms}
              description={params.row.description}
              videourl={params.row.video}
              remarks={params.row.remarks}
/>
          </div>
        );
      },
      filterable: false,
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      //maxWidth: 150,
      align: "center",
      sortable: false,
    },
  ];

  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState(data);

  const requestSearch = (searchValue) => {
    console.log(searchValue);
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);
  return (
    <div style={{ height: 512, width: "90%" }}>
      <DataGrid
        components={{ Toolbar: QuickSearchToolbar }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        pageSize={5}
        loading={loading}
        rowsPerPageOptions={[]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}


      />
    </div>
  );
}
