import { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { base_url } from "../../../../Services/ApiUrl";
import BibleSelect from "./BibleSelect";
import { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthContext";
import { useHistory } from "react-router-dom";

// import Parser from 'html-react-parser';

const OtherBible = (props) => {
  const history = useHistory();
  const {setLogin} =useContext(AuthContext);
  const bookid = props.bookid;
  const bookname = props.bookname;
  const [bible, setBible] = useState(null);
  const [loading, setLoading] = useState(false);

  const chapter = parseInt(props.chapter);
  const verse = parseInt(props.verse);
  const toVerse = props.toVerse;

  const [obverse, setOBVerse] = useState([]);
  const useStyles = makeStyles({
    pContainer: {
      "& p": {
        textAlign: "justify",

        marginLeft: "7px",
        marginRight: "7px",
      },
    },
  });

  useEffect(() => {
    if (bible?.value !== undefined) {
      setLoading(true);
      setOBVerse([]);
      axios
        .get(
          `${base_url}bibles/${bible?.value}/verses?book_code=${bookid}&chapter=${chapter}&verse=${verse}&last_verse=${toVerse}&active=true&skip=0&limit=100`,
          {
            headers: {
              "content-type": "application/json",
              app: "Autographa",
              Authorization: `Bearer ${localStorage.getItem('token') }`,
              // 
            },
          }
        )
        .then((item) => {
          setOBVerse(item.data);
          setLoading(false);
        })
        .catch((error) => {
          if(error.response.status===401)
          {
            history.push("/");
            localStorage.removeItem('token')
            localStorage.removeItem('login')
            localStorage.removeItem('userId')
            setLogin(false);
            alert("Session has Expired. Please Log in again")
      
          }
          console.log(error);
        });
    }
  }, [bible, bookid, chapter, verse, toVerse]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "250px" }}>
          <p>Other Bible</p>
        </div>
        <div style={{ color: "black", margin: "auto" }}>
          <BibleSelect
            onChange={setBible}
            width={150}
            value={bible}
            componentName={"Select Bible"}
          />
        </div>
      </div>
      <div
        style={{
          height: "180px",
          marginTop: "16px",
          background: "#f7f1e3",
          color: "#40407a",
          overflowY: "scroll",
        }}
      >
        <p>
          <br />
          <b>
            [ {bookname} - chapter {chapter}]
          </b>{" "}
        </p>
        <div className={classes.pContainer}>
          {loading ? (
            <>
              <p style={{ textAlign: "center" }}>Loading...</p>
            </>
          ) : obverse.length !== 0 ? (
            <>
              {" "}
              {obverse.map((item, i) => {
                return (
                  <div key={i}>
                    <p>
                      <b>{item["reference"].verseNumber} </b> {item.verseText}
                    </p>
                  </div>
                );
              })}
            </>
          ) : (
            <div>
              <p style={{ textAlign: "center" }}>
                Sorry, Bible Verse Not Available
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherBible;
