import BibleImage from "../Assets/deaf-bible-american-sign-language-deaf-culture.png"


const Home = () => {
  return (
    <div>
      <img className="A" src={BibleImage} alt="Sign Bible Image" width="400" align="left" style={{marginLeft: "30px",zIndex: "9999",marginBottom:40}}/>
    </div>
  );
};

export default Home;